define("m08-2020/lib/WireframeGenerator", ["exports", "three", "m08-2020/lib/Utils", "m08-2020/lib/ThreeExtensions/OutlinesGeometry", "m08-2020/lib/Utils/BooleanOperations"], function (_exports, THREE, _Utils, _OutlinesGeometry, BooleanOperations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WireframeGenerator = void 0;
  const utils = new _Utils.Utils(THREE);

  class WireframeGenerator {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.areaWireframesData = [];
      this.volumeWireframesData = {
        opaque: {
          positives: [],
          negatives: []
        },
        transparent: {
          positives: [],
          negatives: []
        }
      };
    }

    applyWireframesArea(object, objectTransparent, lineProps, objectData) {
      let pointPairs = this.getRegularWireframePointPairs(object);
      pointPairs.forEach(pair => {
        let wireframe = utils.drawConnectedLines(pair, lineProps);
        wireframe.renderOrder = RENDER_ORDER;

        wireframe.onBeforeRender = function (renderer) {
          renderer.clearDepth();
        };

        object.add(wireframe);
        if (objectTransparent) objectTransparent.add(wireframe.clone());
      });
      object.dxfPoints = [{
        id: objectData.id,
        pointPairs: pointPairs.map(pair => pair.map(pt => pt.clone())),
        lineProps
      }];
    }

    applyWireframesVolume(object, objectTransparent, lineProps, objectData) {
      let pointPairs = [];
      this.mergeVolumeWireframes("opaque");
      this.mergeVolumeWireframes("transparent");
      this.createLinesFromWireframeData("opaque", object, lineProps);
      this.createLinesFromWireframeData("transparent", objectTransparent, lineProps, pointPairs);
      this.clearWireframeState();
      object.dxfPoints = [{
        id: objectData.id,
        pointPairs,
        lineProps
      }];
    }

    createLinesFromWireframeData(type, object, lineProps, pointPairs = null) {
      const RENDER_ORDER = -99;
      this.volumeWireframesData[type].forEach(({
        linePoints
      }) => {
        linePoints.forEach(([pointOne, pointTwo]) => {
          if (pointOne.distanceTo(pointTwo) > 0.0001) {
            let line = utils.drawConnectedLines([pointOne, pointTwo], lineProps);
            line.renderOrder = RENDER_ORDER;

            line.onBeforeRender = function (renderer) {
              renderer.clearDepth();
            };

            if (object) object.add(line);
            if (pointPairs) pointPairs.push([pointOne.clone(), pointTwo.clone()]);
          }
        });
      });
    }

    mergeVolumeWireframes(type) {
      let raycaster = new THREE.Raycaster();
      let positives = this.mergeSameTypeWireframe(raycaster, this.volumeWireframesData[type], "positives");
      let negatives = this.mergeSameTypeWireframe(raycaster, this.volumeWireframesData[type], "negatives");
      if (positives.mesh) positives.mesh.material.side = THREE.DoubleSide;
      if (negatives.mesh) negatives.mesh.material.side = THREE.DoubleSide;
      this.volumeWireframesData[type] = [positives, negatives];

      for (let i = 0; i < this.volumeWireframesData[type].length; i++) {
        let objectData = this.volumeWireframesData[type][i];
        let id = objectData.id;
        let mesh = objectData.mesh;
        let objectType = objectData.objectType;
        this.volumeWireframesData[type].filter(obj => id !== obj.id).map(obj => obj.mesh).forEach(objMesh => {
          let newPoints = [];
          objectData.linePoints.forEach(([pointOne, pointTwo]) => {
            pointOne.applyMatrix4(mesh.matrix);
            pointTwo.applyMatrix4(mesh.matrix);
            let firstPointIntersection = this.pointInsideMesh(raycaster, pointOne, pointTwo, objMesh);
            let secondPointIntersection = this.pointInsideMesh(raycaster, pointTwo, pointOne, objMesh);
            let firstPointData = {
              point: pointOne,
              intersection: firstPointIntersection
            };
            let secondPointData = {
              point: pointTwo,
              intersection: secondPointIntersection
            };
            let operation = objectType === 1 ? "AddPoints" : "SubtractPoints";
            let correctedPointPairs = this.getCorrectedLinePoints(firstPointData, secondPointData, operation);
            correctedPointPairs.forEach(pair => {
              if (pair[0].distanceTo(pair[1]) > 0.0001) {
                newPoints.push([pair[0], pair[1]]);
              }
            });
          });
          objectData.linePoints = newPoints;
        });
      }
    }

    mergeSameTypeWireframe(raycaster, volumeWireframes, type) {
      let mergedWireframes = volumeWireframes[type];

      for (let i = 0; i < mergedWireframes.length; i++) {
        let objectData = mergedWireframes[i];
        let id = objectData.id;
        let mesh = objectData.mesh;
        mergedWireframes.filter(obj => id !== obj.id).map(obj => obj.mesh).forEach(objMesh => {
          let newPoints = [];
          objectData.linePoints.forEach(([pointOne, pointTwo]) => {
            pointOne.applyMatrix4(mesh.matrix);
            pointTwo.applyMatrix4(mesh.matrix);
            let firstPointIntersection = this.pointInsideMesh(raycaster, pointOne, pointTwo, objMesh);
            let secondPointIntersection = this.pointInsideMesh(raycaster, pointTwo, pointOne, objMesh);
            let firstPointData = {
              point: pointOne,
              intersection: firstPointIntersection
            };
            let secondPointData = {
              point: pointTwo,
              intersection: secondPointIntersection
            };
            let correctedPointPairs = this.getCorrectedLinePoints(firstPointData, secondPointData, "AddPoints");
            correctedPointPairs.forEach(pair => {
              if (pair[0].distanceTo(pair[1]) > 0.0001) {
                newPoints.push([pair[0], pair[1]]);
              }
            });
          });
          objectData.linePoints = newPoints;
        });
      }

      mergedWireframes = mergedWireframes.reduce((allPos, currentPos) => {
        allPos.id = currentPos.id;
        allPos.objectType = currentPos.objectType;
        allPos.linePoints.push(...currentPos.linePoints);
        allPos.mesh = allPos.mesh ? BooleanOperations.doCSG(allPos.mesh, currentPos.mesh, "union") : currentPos.mesh;
        return allPos;
      }, {
        linePoints: []
      });
      return mergedWireframes;
    }

    getCorrectedLinePoints(firstPtIntersect, secondPtIntersect, operationType) {
      let pointOne = firstPtIntersect.point;
      let firstPointIntersection = firstPtIntersect.intersection;
      let pointTwo = secondPtIntersect.point;
      let secondPointIntersection = secondPtIntersect.intersection;
      let linePairs = [];

      if (operationType === "AddPoints") {
        if (!firstPointIntersection.isIntersect && !secondPointIntersection.isIntersect) {
          if (firstPointIntersection.intersectionPoint && secondPointIntersection.intersectionPoint) {
            let lenPointToPoint = pointOne.distanceTo(pointTwo);
            let lenPointOneToIntersect = pointOne.distanceTo(firstPointIntersection.intersectionPoint);
            let lenPointTwoToIntersect = pointTwo.distanceTo(secondPointIntersection.intersectionPoint);

            if (lenPointToPoint > lenPointOneToIntersect && lenPointToPoint > lenPointTwoToIntersect) {
              linePairs.push([pointOne, firstPointIntersection.intersectionPoint]);
              linePairs.push([pointTwo, secondPointIntersection.intersectionPoint]);
            } else {
              linePairs.push([pointOne, pointTwo]);
            }
          } else {
            linePairs.push([pointOne, pointTwo]);
          }
        } else if (!firstPointIntersection.isIntersect && secondPointIntersection.isIntersect) {
          if (firstPointIntersection.intersectionPoint) linePairs.push([pointOne, secondPointIntersection.intersectionPoint]);
        } else if (firstPointIntersection.isIntersect && !secondPointIntersection.isIntersect) {
          if (secondPointIntersection.intersectionPoint) linePairs.push([pointTwo, firstPointIntersection.intersectionPoint]);
        }
      } else if (operationType === "SubtractPoints") {
        if (firstPointIntersection.isIntersect && secondPointIntersection.isIntersect) {
          linePairs.push([pointOne, pointTwo]);
        } else if (!firstPointIntersection.isIntersect && secondPointIntersection.isIntersect && firstPointIntersection.intersectionPoint) {
          linePairs.push([pointTwo, secondPointIntersection.intersectionPoint]);
        } else if (firstPointIntersection.isIntersect && !secondPointIntersection.isIntersect && secondPointIntersection.intersectionPoint) {
          linePairs.push([pointOne, firstPointIntersection.intersectionPoint]);
        }
      }

      return linePairs;
    }

    pointInsideMesh(raycaster, pointOne, pointTwo, object) {
      if (!object) {
        return {
          isIntersect: false,
          intersectionPoint: null
        };
      }

      let direction = pointTwo.clone().sub(pointOne).normalize();
      raycaster.set(pointOne, direction);
      let intersects = raycaster.intersectObject(object);

      for (let i = 0; i < intersects.length; i++) {
        for (let j = 0; j < intersects.length; j++) {
          if (i != j && Math.abs(intersects[i].distance - intersects[j].distance) < 0.1) {
            intersects[i].markedToDelete = true && !intersects[j].markedToDelete;
          }
        }
      }

      intersects = intersects.filter(intersect => !intersect.markedToDelete);
      let intersectionData = {
        isIntersect: intersects.length % 2 ? true : false,
        intersectionPoint: intersects[0] ? intersects[0].point : null
      };
      return intersectionData;
    }

    getRegularWireframePointPairs(object, threshold = Math.PI / 6) {
      let edgesGeo = new _OutlinesGeometry.OutlinesGeometry(object.geometry);
      let pointPairs = [];
      this.getWireframeLinePairs(edgesGeo, threshold).forEach(([_, pairs]) => pairs.forEach(pair => pointPairs.push(pair)));
      return pointPairs;
    }

    getWireframeLinePairs(edgesGeo, threshold = Math.PI / 6) {
      const DELTA = 0.0001;
      let facesDict = {};

      for (let face of edgesGeo.faces) {
        if (face.splitted) continue;
        let edgeOne = `${face.a},${face.b}`;
        let edgeTwo = `${face.b},${face.c}`;
        let edgeThree = `${face.c},${face.a}`;
        let edgeOneReversed = `${face.b},${face.a}`;
        let edgeTwoReversed = `${face.c},${face.b}`;
        let edgeThreeReversed = `${face.a},${face.c}`;
        if (edgeOneReversed in facesDict) facesDict[edgeOneReversed].push(face);else if (edgeOne in facesDict) facesDict[edgeOne].push(face);else {
          facesDict[edgeOne] = [];
          facesDict[edgeOne].push(face);
        }
        if (edgeTwoReversed in facesDict) facesDict[edgeTwoReversed].push(face);else if (edgeTwo in facesDict) facesDict[edgeTwo].push(face);else {
          facesDict[edgeTwo] = [];
          facesDict[edgeTwo].push(face);
        }
        if (edgeThreeReversed in facesDict) facesDict[edgeThreeReversed].push(face);else if (edgeThree in facesDict) facesDict[edgeThree].push(face);else {
          facesDict[edgeThree] = [];
          facesDict[edgeThree].push(face);
        }
      }

      let linePairs = [];

      for (let [edgeIndices, facePair] of Object.entries(facesDict)) {
        if (facePair.length === 2) {
          let faceOne = facePair[0];
          let faceTwo = facePair[1];

          if (faceOne.normal.angleTo(faceTwo.normal) > threshold) {
            let verticesIdx = edgeIndices.split(",").map(val => parseInt(val));
            let vectorOne = edgesGeo.vertices[verticesIdx[0]];
            let vectorTwo = edgesGeo.vertices[verticesIdx[1]];
            let direction = new THREE.Vector3().subVectors(vectorTwo, vectorOne).normalize();
            let needToAdd = false;

            for (let k = 0; k < linePairs.length; k++) {
              needToAdd = false;
              let vectorData = linePairs[k];
              let dir = vectorData[0];
              let vectorsArr = vectorData[1];
              let dirDot = dir.dot(direction);

              if (dirDot > 1 - DELTA) {
                vectorsArr.push([vectorOne, vectorTwo]);
                k = linePairs.length;
              } else if (dirDot < -1 + DELTA) {
                vectorsArr.push([vectorTwo, vectorOne]);
                k = linePairs.length;
              } else needToAdd = true;
            }

            if (linePairs.length === 0) needToAdd = true;
            if (needToAdd) linePairs.push([direction, [[vectorOne, vectorTwo]]]);
          }
        } else {
          let verticesIdx = edgeIndices.split(",").map(val => parseInt(val));
          let vectorOne = edgesGeo.vertices[verticesIdx[0]];
          let vectorTwo = edgesGeo.vertices[verticesIdx[1]];
          let direction = new THREE.Vector3().subVectors(vectorTwo, vectorOne).normalize();
          let needToAdd = false;

          for (let k = 0; k < linePairs.length; k++) {
            needToAdd = false;
            let vectorData = linePairs[k];
            let dir = vectorData[0];
            let vectorsArr = vectorData[1];
            let dirDot = dir.dot(direction);

            if (dirDot > 1 - DELTA) {
              vectorsArr.push([vectorOne, vectorTwo]);
              k = linePairs.length;
            } else if (dirDot < -1 + DELTA) {
              vectorsArr.push([vectorTwo, vectorOne]);
              k = linePairs.length;
            } else needToAdd = true;
          }

          if (linePairs.length === 0) needToAdd = true;
          if (needToAdd) linePairs.push([direction, [[vectorOne, vectorTwo]]]);
        }
      }

      this.mergeLinePairsSameDirection(linePairs);
      return linePairs;
    }

    mergeLinePairsSameDirection(linePairs) {
      linePairs.forEach(arrData => this.makeLinePairsUnique(arrData));
      linePairs.forEach(([direction, pairs]) => utils.mergeLinePoints(pairs, direction));
    }

    makeLinePairsUnique(arrData) {
      // [ 0: Direction, 1: Pairs array ]
      let vectorPairsArr = arrData[1];
      let uniquePairs = [];
      vectorPairsArr.forEach(pair => {
        let isUnique = true;
        uniquePairs.forEach(uniquePair => {
          if (pair[0].equals(uniquePair[0]) && pair[1].equals(uniquePair[1])) isUnique = false;
        });
        if (isUnique) uniquePairs.push(pair);
      });
      arrData[1] = uniquePairs;
    }

    getVolumeWireframeData(mesh, linePairsForOpaque, linePairsForTransparent, objectType) {
      let intersectionGeo = mesh.geometry.clone(); // For checking if line intersects faces

      intersectionGeo.applyMatrix4(mesh.matrix);
      let intersectionMesh = new THREE.Mesh(intersectionGeo, new THREE.MeshBasicMaterial({
        side: THREE.DoubleSide
      }));

      if (objectType === 1) {
        this.volumeWireframesData.opaque.positives.push({
          id: intersectionMesh.uuid,
          linePoints: linePairsForOpaque,
          mesh: intersectionMesh,
          objectType
        });
        this.volumeWireframesData.transparent.positives.push({
          id: intersectionMesh.uuid,
          linePoints: linePairsForTransparent,
          mesh: intersectionMesh,
          objectType
        });
      } else if (objectType === 2) {
        this.volumeWireframesData.opaque.negatives.push({
          id: intersectionMesh.uuid,
          linePoints: linePairsForOpaque,
          mesh: intersectionMesh,
          objectType
        });
        this.volumeWireframesData.transparent.negatives.push({
          id: intersectionMesh.uuid,
          linePoints: linePairsForTransparent,
          mesh: intersectionMesh,
          objectType
        });
      }
    }

    clearWireframeState() {
      this.areaWireframesData = [];
      this.volumeWireframesData = {
        opaque: {
          positives: [],
          negatives: []
        },
        transparent: {
          positives: [],
          negatives: []
        }
      };
    }

  }

  _exports.WireframeGenerator = WireframeGenerator;
});