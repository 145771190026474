define("m08-2020/lib/ClippingUtils/DynamicSectionalPlane", ["exports", "three", "dat.gui", "m08-2020/lib/ClippingUtils/OutlineGenerator", "m08-2020/lib/Utils"], function (_exports, THREE, dat, _OutlineGenerator, _Utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DynamicSectionalPlane = void 0;
  const utils = new _Utils.Utils(THREE);

  class DynamicSectionalPlane {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.gui = new dat.GUI();
      this.OutlineGenerator = new _OutlineGenerator.OutlineGenerator(GraphicsThree3D);
      this.sectionalPlanes = [];
      this.renderOrder = 1;
    }

    applySectionalPlanes() {
      this.sectionalPlanes.forEach(planeData => {
        let targets = planeData.targets;
        targets.drawingObjects.forEach(({
          mesh,
          clippingPlanes
        }) => {
          let targetClippingPlanes = this.sectionalPlanes.filter(plane => clippingPlanes.includes(plane.id));
          this.clipMesh(planeData, mesh, targetClippingPlanes);
        });
        this.addPlaneToGUI(planeData);
      });
    }

    initSectionalPlanes(dynamicSectionalPlanes) {
      dynamicSectionalPlanes.forEach(plane => {
        let planeData = plane.planeData;
        let sectionalPlaneData = {
          clippingPlane: this.makePlane(planeData),
          positionVector: utils.initVector3(planeData.positionVector),
          id: planeData.id,
          range: planeData.range,
          properties: plane.properties,
          stencilPlanes: [],
          targets: {
            drawingObjects: []
          }
        };
        this.sectionalPlanes.push(sectionalPlaneData);
      });
    }

    addPlaneToGUI(planeData) {
      const clippingPlane = planeData.clippingPlane;
      clippingPlane.isVisible = true;
      const guiPlane = this.gui.addFolder(`Sectional Plane ${planeData.id}`);
      guiPlane.add(clippingPlane, 'constant').min(clippingPlane.constant - planeData.range).max(clippingPlane.constant + planeData.range).onChange(() => this.removeOutlineAndMoveStencil(planeData)).onFinishChange(() => this.redrawStencilOutline(planeData));
      guiPlane.add(clippingPlane, "isVisible").onChange(isVisible => this.togglePlaneVisibility(planeData, clippingPlane, isVisible));
      guiPlane.open();
    }

    redrawStencilOutline(planeData) {
      let clippingPlane = planeData.clippingPlane;
      let lineProps = planeData.properties.intersectionLine;

      if (lineProps) {
        planeData.stencilPlanes.forEach(stencilData => {
          let outlines = stencilData.outlines;
          let stencilClippingPlanes = stencilData.stencilClippingPlanes;
          let targetScene = stencilData.targetScene;
          let targetMesh = stencilData.targetMesh;
          let lines = this.OutlineGenerator.drawStencilOutline(targetMesh, clippingPlane, lineProps);
          this.OutlineGenerator.clearPointsOfIntersection();
          lines.forEach(line => {
            line.material.clippingPlanes = stencilClippingPlanes.filter(plane => !plane.clippingPlane.equals(clippingPlane)).map(plane => plane.clippingPlane);
            targetScene.add(line);
            outlines.push(line);
          });
        });
      }
    }

    removeOutlineAndMoveStencil(planeData) {
      this.removeCurrentOutline(planeData);
      this.moveStencilPlanes(planeData);
    }

    removeCurrentOutline(planeData) {
      planeData.stencilPlanes.forEach(stencilData => {
        stencilData.outlines.forEach(outline => stencilData.targetScene.remove(outline));
        stencilData.outlines = [];
      });
    }

    moveStencilPlanes(planeData) {
      let {
        clippingPlane,
        stencilPlanes
      } = planeData;
      stencilPlanes.forEach(({
        stencilPlane
      }) => {
        let projectedPlanePos = stencilPlane.position.clone().projectOnVector(clippingPlane.normal);
        let clippingPlanePos = clippingPlane.normal.clone().multiplyScalar(-clippingPlane.constant);
        let delta = clippingPlanePos.sub(projectedPlanePos);
        stencilPlane.position.add(delta);
      });
    }

    clipMesh(planeData, mesh, targetPlanes) {
      mesh.updateMatrix();
      let clippingPlane = planeData.clippingPlane;
      let positionVector = planeData.positionVector;
      let areaProps = planeData.properties.intersectionArea;
      let lineProps = planeData.properties.intersectionLine;
      let targetScene = this.GraphicsThree3D.stencilScenes.length ? new THREE.Scene() : this.GraphicsThree3D.scene;

      if (mesh.stencilNeeded) {
        let stencilAndOutline = {
          stencilPlane: null,
          outlines: [],
          stencilClippingPlanes: targetPlanes,
          targetMesh: mesh,
          stencilOpacity: 0.0,
          outlineOpacity: 0.0,
          targetScene
        };

        if (areaProps) {
          stencilAndOutline.stencilOpacity = areaProps.color.alpha / 255.0;
          let [frontFaceStencilMat, backFaceStencilMat, planeStencilMat] = this.getClippingMaterials(areaProps);
          this.clipStencilMaterials(clippingPlane, targetPlanes, [frontFaceStencilMat, backFaceStencilMat, planeStencilMat]);
          let meshGeo = mesh.geometry.clone();
          let frontMesh = new THREE.Mesh(meshGeo, frontFaceStencilMat);
          frontMesh.applyMatrix4(mesh.matrix);
          let backMesh = new THREE.Mesh(meshGeo, backFaceStencilMat);
          backMesh.applyMatrix4(mesh.matrix);
          frontMesh.renderOrder = this.renderOrder;
          backMesh.renderOrder = this.renderOrder;
          frontMesh.position.copy(mesh.position);
          backMesh.position.copy(mesh.position);
          targetScene.add(frontMesh);
          targetScene.add(backMesh);
          let planeMesh = this.createStencilPlaneMesh(clippingPlane, positionVector, planeStencilMat);
          this.GraphicsThree3D.initWireframeObject(planeMesh, new THREE.Mesh(), this.GraphicsThree3D.jsonData, targetScene);
          targetScene.add(planeMesh);
          stencilAndOutline.stencilPlane = planeMesh;
        }

        if (lineProps) {
          stencilAndOutline.outlineOpacity = lineProps.color.alpha / 255.0;
          let lines = this.OutlineGenerator.drawStencilOutline(mesh, clippingPlane, lineProps);
          this.OutlineGenerator.clearPointsOfIntersection();
          lines.forEach(line => {
            line.material.clippingPlanes = targetPlanes.filter(plane => !plane.clippingPlane.equals(clippingPlane)).map(plane => plane.clippingPlane);
            targetScene.add(line);
          });
          stencilAndOutline.outlines = lines;
        }

        planeData.stencilPlanes.push(stencilAndOutline);
      }

      this.GraphicsThree3D.stencilScenes.push(targetScene);
      if (mesh.children) mesh.children.forEach(child => this.clipMaterial(child.material, clippingPlane));
      this.clipMaterial(mesh.material, clippingPlane);
    }

    clipStencilMaterials(clippingPlane, targetPlanes, [frontFaceStencilMat, backFaceStencilMat, planeStencilMat]) {
      frontFaceStencilMat.clippingPlanes = [clippingPlane];
      backFaceStencilMat.clippingPlanes = [clippingPlane];
      planeStencilMat.clippingPlanes = targetPlanes.filter(plane => !plane.clippingPlane.equals(clippingPlane)).map(plane => plane.clippingPlane);
    }

    createStencilPlaneMesh(clippingPlane, positionVector, planeStencilMat) {
      let planeNormal = clippingPlane.normal.clone();
      let forwardVector = new THREE.Vector3(0, 0, -1);
      let planeGeom = new THREE.PlaneBufferGeometry();
      let planeMesh = new THREE.Mesh(planeGeom, planeStencilMat);
      planeMesh.scale.setScalar(1000);
      planeMesh.position.copy(positionVector);
      planeMesh.quaternion.setFromUnitVectors(forwardVector, planeNormal);

      planeMesh.onAfterRender = function (renderer) {
        renderer.clearStencil();
      };

      planeMesh.renderOrder = this.renderOrder + 0.1;
      return planeMesh;
    }

    getClippingMaterials(materialProps) {
      //https://discourse.threejs.org/t/capping-clipped-planes-using-stencil-on-a-buffergeometry/18407/18
      // PASS 1
      // everywhere that the back faces are visible (clipped region) the stencil
      // buffer is incremented by 1.
      let backFaceStencilMat = this.createBackStencilMat(); // PASS 2
      // everywhere that the front faces are visible the stencil
      // buffer is decremented back to 0.

      let frontFaceStencilMat = this.createFrontStencilMat(); // PASS 3
      // draw the plane everywhere that the stencil buffer != 0, which will
      // only be in the clipped region where back faces are visible.

      let planeStencilMat = this.createPlaneStencilMat(materialProps);
      return [frontFaceStencilMat, backFaceStencilMat, planeStencilMat];
    }

    createPlaneStencilMat(materialProps) {
      let planeStencilMat = this.getPlaneStencilMaterial(materialProps);
      planeStencilMat.stencilWrite = true;
      planeStencilMat.stencilRef = 0;
      planeStencilMat.stencilFunc = THREE.NotEqualStencilFunc;
      planeStencilMat.stencilFail = THREE.ReplaceStencilOp;
      planeStencilMat.stencilZFail = THREE.ReplaceStencilOp;
      planeStencilMat.stencilZPass = THREE.ReplaceStencilOp;
      return planeStencilMat;
    }

    createBackStencilMat() {
      let backFaceStencilMat = new THREE.MeshBasicMaterial();
      backFaceStencilMat.depthWrite = false;
      backFaceStencilMat.depthTest = false;
      backFaceStencilMat.colorWrite = false;
      backFaceStencilMat.stencilWrite = true;
      backFaceStencilMat.stencilFunc = THREE.AlwaysStencilFunc;
      backFaceStencilMat.side = THREE.BackSide;
      backFaceStencilMat.stencilFail = THREE.IncrementWrapStencilOp;
      backFaceStencilMat.stencilZFail = THREE.IncrementWrapStencilOp;
      backFaceStencilMat.stencilZPass = THREE.IncrementWrapStencilOp;
      return backFaceStencilMat;
    }

    createFrontStencilMat() {
      let frontFaceStencilMat = new THREE.MeshBasicMaterial();
      frontFaceStencilMat.depthWrite = false;
      frontFaceStencilMat.depthTest = false;
      frontFaceStencilMat.colorWrite = false;
      frontFaceStencilMat.stencilWrite = true;
      frontFaceStencilMat.stencilFunc = THREE.AlwaysStencilFunc;
      frontFaceStencilMat.side = THREE.FrontSide;
      frontFaceStencilMat.stencilFail = THREE.DecrementWrapStencilOp;
      frontFaceStencilMat.stencilZFail = THREE.DecrementWrapStencilOp;
      frontFaceStencilMat.stencilZPass = THREE.DecrementWrapStencilOp;
      return frontFaceStencilMat;
    }

    getPlaneStencilMaterial(areaProps) {
      const SIZE = 20;
      let planeStencilMat;
      let colorProps = areaProps.color;

      if (areaProps.hatching.type === 2) {
        let orientationVector = utils.normalizeVector(areaProps.hatching.orientationVector);
        let hatchSpacing = areaProps.hatching.factor;
        let hatchColor = areaProps.hatching.color;
        let hatchThickness = areaProps.hatching.thickness;
        planeStencilMat = this.GraphicsThree3D.makeCanvasMaterial(colorProps, hatchColor, orientationVector, hatchSpacing, hatchThickness);
        planeStencilMat.map.wrapS = planeStencilMat.map.wrapT = THREE.RepeatWrapping;
        planeStencilMat.map.repeat.set(SIZE, SIZE);
      } else if (areaProps.hatching.type === 1) {
        planeStencilMat = this.GraphicsThree3D.getMaterialFromColor(colorProps);
      }

      return planeStencilMat;
    }

    clipMaterial(material, plane) {
      if (material.length) material.forEach(mat => this.addClippingPlane(mat, plane));else this.addClippingPlane(material, plane);
    }

    addClippingPlane(material, plane) {
      if (material.clippingPlanes) material.clippingPlanes.push(plane);else material.clippingPlanes = [plane];
    }

    togglePlaneVisibility(planeData, clippingPlane, isVisible) {
      if (isVisible) this.showClippingPlane(planeData, clippingPlane);else this.hideClippingPlane(planeData, clippingPlane);
    }

    showClippingPlane(planeData, clippingPlane) {
      planeData.stencilPlanes.forEach(planeInfo => planeInfo.outlines.forEach(lineMesh => lineMesh.material.opacity = planeInfo.outlineOpacity));
      planeData.targets.drawingObjects.forEach(meshData => this.addClippingPlaneToMesh(meshData.mesh, clippingPlane));
      this.addClippingWhenVisible(clippingPlane);
      planeData.stencilPlanes.forEach(plane => plane.stencilPlane.material.opacity = plane.stencilOpacity);
    }

    hideClippingPlane(planeData, clippingPlane) {
      planeData.stencilPlanes.forEach(planeInfo => planeInfo.outlines.forEach(lineMesh => lineMesh.material.opacity = 0));
      planeData.targets.drawingObjects.forEach(meshData => this.removeClippingPlaneFromMesh(meshData.mesh, clippingPlane));
      this.removeClippingWhenNotVisible(clippingPlane);
      planeData.stencilPlanes.forEach(plane => plane.stencilPlane.material.opacity = 0);
    }

    addClippingWhenVisible(clippingPlane) {
      this.sectionalPlanes.forEach(planeData => {
        if (!planeData.clippingPlane.equals(clippingPlane)) {
          planeData.stencilPlanes.forEach(stencilData => {
            stencilData.stencilPlane.material.clippingPlanes.push(clippingPlane);
            stencilData.outlines.forEach(lineMesh => lineMesh.material.clippingPlanes.push(clippingPlane));
          });
        }
      });
    }

    removeClippingWhenNotVisible(clippingPlane) {
      this.sectionalPlanes.forEach(planeData => {
        planeData.stencilPlanes.forEach(stencilData => {
          stencilData.stencilPlane.material.clippingPlanes = stencilData.stencilPlane.material.clippingPlanes.filter(cp => !cp.equals(clippingPlane));
          stencilData.outlines.forEach(lineMesh => lineMesh.material.clippingPlanes = lineMesh.material.clippingPlanes.filter(cp => !cp.equals(clippingPlane)));
        });
      });
    }

    addClippingPlaneToMesh(mesh, plane) {
      if (mesh.children) mesh.children.forEach(child => {
        if (child.material.length > 0) child.material.forEach(mat => mat.clippingPlanes.push(plane));else child.material.clippingPlanes.push(plane);
      });
      if (mesh.material.length > 0) mesh.material.forEach(mat => mat.clippingPlanes.push(plane));else mesh.material.clippingPlanes.push(plane);
    }

    removeClippingPlaneFromMesh(mesh, plane) {
      if (mesh.children) mesh.children.forEach(child => this.removeClippingPlane(child.material, plane));
      this.removeClippingPlane(mesh.material, plane);
    }

    removeClippingPlane(material, plane) {
      if (material.length > 0) material.forEach(mat => mat.clippingPlanes = mat.clippingPlanes.filter(cp => !cp.equals(plane)));else material.clippingPlanes = material.clippingPlanes.filter(cp => !cp.equals(plane));
    }

    makePlane(planeData) {
      let positionVector = utils.initVector3(planeData.positionVector);
      let normalVector = utils.initVector3(planeData.normalVector).negate().normalize();
      let planeDistance = positionVector.clone().projectOnVector(normalVector).length();
      let distanceSign = positionVector.normalize().dot(normalVector) > 0 ? -1 : 1;
      planeDistance *= distanceSign;
      let plane = new THREE.Plane(normalVector, planeDistance);
      return plane;
    }

  }

  _exports.DynamicSectionalPlane = DynamicSectionalPlane;
});