define("m08-2020/lib/FigureLoaders/DrawingObjectComponents/Point", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Point = void 0;

  class Point {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.THREE = GraphicsThree3D.THREE;
      this.utils = GraphicsThree3D.utils;
      this.CSG = GraphicsThree3D.CSG;
    }

    drawPoint(pointData, materialProps) {
      let pointProps = materialProps.point;
      let lineProps = materialProps.line;
      let pointType = pointData.objectGeneration.type;
      let pointMesh;

      if (pointType == 1) {
        pointMesh = this.drawPointPlanar(pointData, pointProps);
        pointMesh.generationType = "point";
      } else if (pointType == 2) {
        pointMesh = this.drawPointExtruded(pointData, lineProps);
        pointMesh.generationType = "line";
      } else {
        pointMesh = this.drawPointRotated(pointData, lineProps);
        pointMesh.generationType = "line";
      }

      return pointMesh;
    }

    drawPointPlanar(pointData, pointProps) {
      let insertionPoint = pointData.insertionPoint;
      let pointRadius = pointProps.size / 2;
      let pointColor = pointProps.color;
      let pointGeo = new this.THREE.SphereBufferGeometry(pointRadius, 32, 16);
      let pointMaterial = this.utils.getMaterialFromColorProps(pointColor);
      let pointMesh = new this.THREE.Mesh(pointGeo, pointMaterial);
      pointMesh.position.set(insertionPoint.x, insertionPoint.y, insertionPoint.z);
      return pointMesh;
    }

    drawPointExtruded(pointData, lineProps) {
      let insertionPoint = pointData.insertionPoint;
      let extrusionVector = pointData.objectGeneration.extrusionVector;
      let [pointOne, pointTwo] = this.getExtrudedPointPoints(insertionPoint, extrusionVector);
      let lineMesh = this.utils.drawLine(pointOne, pointTwo, lineProps, true);
      return lineMesh;
    }

    getExtrudedPointPoints(insertionPoint, extrusionVector) {
      let extrusion = this.utils.initVector3(extrusionVector);
      let pointOne = this.utils.initVector3(insertionPoint);
      let pointTwo = pointOne.clone().add(extrusion);
      return [pointOne, pointTwo];
    }

    drawPointRotated(pointData, lineProps) {
      let circlePoints = this.getRotatedPoints(pointData);
      let lineMesh = this.utils.drawConnectedLines(circlePoints, lineProps);
      return lineMesh;
    }

    getRotatedPoints(pointData) {
      let insertionPoint = pointData.insertionPoint;
      let rotationPoint = pointData.objectGeneration.rotationPoint;
      let rotationAxis = this.utils.initVector3(pointData.objectGeneration.rotationAxis);
      let rotationAngle = pointData.objectGeneration.rotationAngle * Math.PI / 180;
      let rotationPitch = pointData.objectGeneration.rotationPitch;
      let pivotPoint = new this.THREE.Object3D();
      pivotPoint.position.set(rotationPoint.x, rotationPoint.y, rotationPoint.z);
      let positionPoint = new this.THREE.Object3D();
      positionPoint.position.set(insertionPoint.x, insertionPoint.y, insertionPoint.z);
      pivotPoint.attach(positionPoint);
      let startPoint = this.utils.initVector3(insertionPoint);
      let circlePoints = [startPoint];
      let positionVector = new this.THREE.Vector3(); // dummy vector for obtaining results

      let numOfSegments = 64 * rotationAngle / (Math.PI * 2);

      for (let i = 0; i < numOfSegments; i++) {
        pivotPoint.rotateOnAxis(rotationAxis, rotationAngle / numOfSegments);
        pivotPoint.updateMatrixWorld();
        let newPoint = new this.THREE.Vector3().add(positionPoint.getWorldPosition(positionVector));
        newPoint.add(rotationAxis.clone().normalize().multiplyScalar((i + 1) * rotationPitch / numOfSegments));
        circlePoints.push(newPoint);
      }

      return circlePoints;
    }

  }

  _exports.Point = Point;
});