define("m08-2020/lib/ThreeExtensions/RotatedGeometry", ["exports", "three"], function (_exports, THREE) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RotatedGeometry = void 0;

  class RotatedGeometry extends THREE.Geometry {
    constructor(points, rotationData) {
      super();
      this.generateVertices(points, rotationData);
    }

    generateVertices(points, rotationData) {
      console.log("heya");
      console.log(points, rotationData);
      let {
        rotationPoint,
        rotationAxis,
        rotationAngle,
        rotationPitch
      } = rotationData;
      let testExtrusion = new THREE.Vector3(0, 0, 10);
      let testPoints = [new THREE.Vector3(-10, -10, 0), new THREE.Vector3(10, -10, 0), new THREE.Vector3(10, 10, 0), new THREE.Vector3(-10, 10, 0)];
      this.vertices.push(...testPoints);
      this.vertices.push(...testPoints.map(pt => pt.clone().add(testExtrusion)));
      this.faces.push(new THREE.Face3(0, 1, 2), new THREE.Face3(0, 2, 3), new THREE.Face3(1, 5, 6), new THREE.Face3(1, 6, 2), new THREE.Face3(2, 6, 7), new THREE.Face3(2, 7, 3), new THREE.Face3(3, 7, 4), new THREE.Face3(3, 4, 0), new THREE.Face3(0, 4, 5), new THREE.Face3(0, 5, 1), new THREE.Face3(1, 5, 6), new THREE.Face3(0, 6, 2));
    }

  }

  _exports.RotatedGeometry = RotatedGeometry;
});