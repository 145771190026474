define("m08-2020/lib/FigureLoaders/DimensionalChainLoader", ["exports", "m08-2020/lib/Utils", "three-spritetext"], function (_exports, _Utils, _threeSpritetext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DimensionalChainLoader = void 0;

  class DimensionalChainLoader {
    constructor(GraphicsThree3D) {
      this.THREE = GraphicsThree3D.THREE;
      this.fontUrlArray = GraphicsThree3D.fontUrlArray;
      this.GraphicsThree3D = GraphicsThree3D;
      this.utils = new _Utils.Utils(GraphicsThree3D.THREE);
      this.intersectionDetection = GraphicsThree3D.DimensionalChainIntersectionDetection; ///

      this.drawMaskette = this.drawMaskette.bind(this);
    }

    drawDimensionalChains(dimensionalChains) {
      if (dimensionalChains && dimensionalChains.length > 0 && !this.isOldDimChainJSON(dimensionalChains[0])) this.sortChainsByRank(dimensionalChains);
      dimensionalChains.forEach(dimensionalChain => {
        let meshArray = this.drawMaskette(dimensionalChain);
        meshArray.forEach(mesh => {
          let drawnObject = new Object();
          drawnObject.id = dimensionalChain.id;
          drawnObject.type = "maskette";
          drawnObject.data = dimensionalChain;
          drawnObject.sceneObject = mesh;
          drawnObject.isOnScene = mesh.IsHighLight ? false : true;
          this.GraphicsThree3D.ThreeObjects.push(drawnObject);
          if (!mesh.IsHighLight) this.GraphicsThree3D.scene.add(drawnObject.sceneObject);
        });
      });
      this.intersectionDetection.chainArr = [];
    }

    sortChainsByRank(dimensionalChains) {
      dimensionalChains.sort((a, b) => b.alignments.dimensionalChainArrangement.rankOrder - a.alignments.dimensionalChainArrangement.rankOrder);
    }

    isOldDimChainJSON(chainData) {
      return chainData.alignments.view3d && chainData.alignments.view3d.hasOwnProperty("textAlignment") || chainData.alignments.views2d && chainData.alignments.views2d[0].hasOwnProperty("textAlignment");
    }

    drawMaskette(dimensionalChain, alignment2D = null) {
      let meshArray = [];
      let alignments = dimensionalChain.alignments;
      let alignmentData = alignments.view3d;
      let addToVisControl = true;

      if (alignment2D) {
        addToVisControl = false;
        alignmentData = { ...alignmentData,
          ...alignment2D
        };
      }

      if (this.isOldDimChainJSON(dimensionalChain)) {
        alignments.textAlignment = alignmentData.textAlignment;
        alignments.textArrangement = 2;
        alignments.dimensionalChainArrangement = {
          distance: 0,
          rankOrder: 0,
          type: 2
        };
      }

      let alignmentOffset = alignmentData.offsetVector;
      let insertionPoints = dimensionalChain.insertionPoints;

      if (alignmentOffset && (alignmentOffset.x || alignmentOffset.y || alignmentOffset.z)) {
        ///
        if (alignments.dimensionalChainArrangement.type === 1) {
          let chainType = this.getChainType(dimensionalChain);
          let offsetVector = this.getChainOffsetVector(dimensionalChain, chainType, alignmentData);
          alignmentOffset = {
            x: offsetVector.x,
            y: offsetVector.y,
            z: offsetVector.z
          };
          alignmentData.offsetVector = alignmentOffset;
        } ///


        let chain = this.drawDimensionalChain(dimensionalChain, alignmentData);

        if (chain) {
          insertionPoints.forEach(insertionPoint => {
            let insertionVector = insertionPoint.insertionVector;
            let objectRotationAngle = insertionPoint.rotationAngle;
            let chainObject = new this.THREE.Object3D();

            if (objectRotationAngle) {
              objectRotationAngle = objectRotationAngle * Math.PI / 180;
              let rotationAxis = this.utils.initVector3(insertionPoint.rotationAxis);
              let rotationPoint = this.utils.initVector3(insertionPoint.rotationPoint);
              chainObject.position.copy(rotationPoint);
              chainObject.attach(chain.clone());
              chainObject.updateMatrix();
              chainObject.rotateOnAxis(rotationAxis, objectRotationAngle);
            } else chainObject.add(chain.clone());

            chainObject.position.add(insertionVector);
            if (addToVisControl) this.GraphicsThree3D.addObjectToVisControl(chainObject, "dimensionalChain");
            meshArray.push(chainObject);
          });
        }
      }

      return meshArray;
    }

    drawDimensionalChain(dimensionalChain, alignmentData) {
      let referencePoints = dimensionalChain.referencePoints; // Components

      let hasBaseline = dimensionalChain.components.baseLine;
      let baseLineOverhang = dimensionalChain.components.baseLineOverhang;
      let endLine = dimensionalChain.components.endLine;
      let endSymbol = dimensionalChain.components.endSymbol;
      let texts = dimensionalChain.components.texts; // Alignment

      let alignments = dimensionalChain.alignments;
      let alignmentOffset = this.utils.initVector3(alignmentData.offsetVector); // Properties

      let lineProps = dimensionalChain.properties.line;
      let areaProps = dimensionalChain.properties.area;
      let textProps = dimensionalChain.properties.text; // Materials

      let areaMaterial = areaProps ? this.GraphicsThree3D.getMaterialFromColor(areaProps.color) : null;
      let chain = new this.THREE.Object3D();
      let chainType = this.getChainType(dimensionalChain);

      if (chainType === "linear") {
        let startPoint = this.utils.initVector3(referencePoints[0]);
        let endPoint = this.utils.initVector3(referencePoints[referencePoints.length - 1]);
        let chainDirection = new this.THREE.Vector3().copy(endPoint).sub(startPoint).normalize();
        let chainNormal = new this.THREE.Vector3().crossVectors(chainDirection, alignmentOffset.clone().normalize()).normalize();
        let dx = endPoint.x - startPoint.x;
        let dy = endPoint.y - startPoint.y; // baseLine

        if (hasBaseline) {
          let lineMesh = this.utils.drawConnectedLines([startPoint, endPoint], lineProps);
          chain.add(lineMesh); ///

          let lineForIntersection = this.GraphicsThree3D.drawLine(startPoint, endPoint, lineProps);
          let intersectionObjects = this.GraphicsThree3D.getThreeSideFaceObjects(lineForIntersection);
          intersectionObjects.forEach(object => {
            object.position.add(alignmentOffset);
            object.updateMatrixWorld();
          });
          this.intersectionDetection.chainArr.push(intersectionObjects);
        } // baseLineOverhang


        if (baseLineOverhang && baseLineOverhang.position && baseLineOverhang.length) {
          let overhangLength = baseLineOverhang.length;
          let overhangPosition = baseLineOverhang.position;
          let offset = new this.THREE.Vector3().copy(chainDirection).multiplyScalar(overhangLength);

          if (overhangPosition === 1 || overhangPosition === 2) {
            let overhangPoint = startPoint.clone().sub(offset);
            let lineMesh = this.utils.drawConnectedLines([startPoint, overhangPoint], lineProps);
            chain.add(lineMesh);
          }

          if (overhangPosition === 1 || overhangPosition === 3) {
            let overhangPoint = endPoint.clone().add(offset);
            let lineMesh = this.utils.drawConnectedLines([endPoint, overhangPoint], lineProps);
            chain.add(lineMesh);
          }
        }

        for (let i = 0; i < referencePoints.length - 1; i++) {
          let referencePointOne = this.utils.initVector3(referencePoints[i]);
          let referencePointTwo = this.utils.initVector3(referencePoints[i + 1]); // endLine

          if (endLine) {
            let offsetDir = this.utils.initVector3(alignmentOffset).normalize();
            let lineMesh = this.drawEndLine(offsetDir, endLine, lineProps, referencePointOne);
            chain.add(lineMesh);

            if (i == referencePoints.length - 2) {
              let lineMesh = this.drawEndLine(offsetDir, endLine, lineProps, referencePointTwo);
              chain.add(lineMesh);
            }
          } // endSymbol


          if (endSymbol) {
            let endSymbolType = endSymbol.type;

            if (endSymbolType === 1 && endSymbol.size) {
              let circleRadius = endSymbol.size / 2;
              let circle = this.drawEndSymbolCircle(circleRadius, areaMaterial, lineProps, chainNormal, referencePointOne);
              chain.add(circle);

              if (i == referencePoints.length - 2) {
                let circle = this.drawEndSymbolCircle(circleRadius, areaMaterial, lineProps, chainNormal, referencePointTwo);
                chain.add(circle);
              }
            } else if (endSymbolType === 2) {
              let offsetDir = this.utils.initVector3(alignmentOffset).normalize();
              let lineMesh = this.drawEndSymbolLine(offsetDir, chainNormal, referencePointOne, endSymbol, lineProps);
              chain.add(lineMesh);

              if (i == referencePoints.length - 2) {
                let lineMesh = this.drawEndSymbolLine(offsetDir, chainNormal, referencePointTwo, endSymbol, lineProps);
                chain.add(lineMesh);
              }
            } else if (endSymbolType === 3) {
              let arrowRadius = endSymbol.size / 4;
              let arrowLength = endSymbol.size;
              let arrowOne = this.makeArrow(arrowRadius, arrowLength, areaMaterial, lineProps);
              let arrowTwo = this.makeArrow(arrowRadius, arrowLength, areaMaterial, lineProps);
              arrowOne.rotateOnWorldAxis(new this.THREE.Vector3(0, 0, 1), -Math.PI - Math.atan(dx / dy));
              arrowTwo.rotateOnWorldAxis(new this.THREE.Vector3(0, 0, 1), -Math.atan(dx / dy));
              arrowOne.position.add(referencePointOne);
              arrowTwo.position.add(referencePointTwo);
              chain.add(arrowOne);
              chain.add(arrowTwo);
            } else if (endSymbolType === 4) {
              let arrowRadius = endSymbol.size / 2;
              let arrowSize = endSymbol.size;
              let arrow = this.makeArrow(arrowRadius, arrowSize, areaMaterial, lineProps);
              arrow.rotateOnWorldAxis(new this.THREE.Vector3(0, 0, 1), -Math.atan(dx / dy));
              arrow.position.add(referencePointOne);
              chain.add(arrow);

              if (i == referencePoints.length - 2) {
                let arrowRadius = endSymbol.size / 2;
                let arrowSize = endSymbol.size;
                let arrow = this.makeArrow(arrowRadius, arrowSize, areaMaterial, lineProps);
                arrow.rotateOnWorldAxis(new this.THREE.Vector3(0, 0, 1), -Math.atan(dx / dy));
                arrow.position.add(referencePointTwo);
                chain.add(arrow);
              }
            } else if (endSymbolType === 5) {
              let arrowRadius = endSymbol.size / 2;
              let arrowSize = endSymbol.size;
              let arrow = this.makeArrow(arrowRadius, arrowSize, areaMaterial, lineProps);
              arrow.rotateOnWorldAxis(new this.THREE.Vector3(0, 0, 1), -Math.PI - Math.atan(dx / dy));
              arrow.position.add(referencePointOne);
              chain.add(arrow);

              if (i == referencePoints.length - 2) {
                let arrowRadius = endSymbol.size / 2;
                let arrowSize = endSymbol.size;
                let arrow = this.makeArrow(arrowRadius, arrowSize, areaMaterial, lineProps);
                arrow.rotateOnWorldAxis(new this.THREE.Vector3(0, 0, 1), -Math.PI - Math.atan(dx / dy));
                arrow.position.add(referencePointTwo);
                chain.add(arrow);
              }
            }
          }
        } // text


        let canDrawText = this.canDrawText(dimensionalChain);

        if (canDrawText) {
          let font = this.GraphicsThree3D.loadFont(textProps);
          let startLength = startPoint.length();
          let endLength = endPoint.length();
          let maxLength = Math.max(startLength, endLength);
          let minLength = Math.min(startLength, endLength);

          if (alignments.textAlignment === 1) {
            for (let text of texts) {
              let textInsideChain = this.textInsideChain(text, startPoint, chainDirection, [minLength, maxLength]);

              if (textInsideChain) {
                let textInsertionPoint = text.insertionPoint;
                let textContent = text.content;
                let textOffset = text.offset;
                let pointsDir = new this.THREE.Vector3().subVectors(endPoint, startPoint).normalize();
                let offsetDir = this.utils.initVector3(alignmentOffset).normalize();
                let crossPointsOffset = new this.THREE.Vector3().crossVectors(pointsDir, offsetDir);
                let textOffsetDir = new this.THREE.Vector3().crossVectors(crossPointsOffset, pointsDir).normalize();
                let textPosition = this.utils.initVector3(textInsertionPoint);
                textPosition.add(textOffsetDir.multiplyScalar(textOffset));
                let textMesh = this.makeTextSprite(textContent, textPosition, textProps);
                chain.add(textMesh);
              }
            }
          } else if (alignments.textAlignment === 2 && alignmentData.normalVector && alignmentData.directionVector) {
            let textDataArr = [];
            let tempIntersectionTexts = [];
            let isIntersection = false;

            for (let text of texts) {
              let textInsideChain = this.textInsideChain(text, startPoint, chainDirection, [minLength, maxLength]);

              if (textInsideChain) {
                let textInsertionPoint = text.insertionPoint;
                let textOffset = text.offset;
                let pointsDir = new this.THREE.Vector3().subVectors(endPoint, startPoint).normalize();
                let offsetDir = this.utils.initVector3(alignmentOffset).normalize();
                let crossPointsOffset = new this.THREE.Vector3().crossVectors(pointsDir, offsetDir);
                let textOffsetDir = new this.THREE.Vector3().crossVectors(crossPointsOffset, pointsDir).normalize();
                let textPosition = this.utils.initVector3(textInsertionPoint);
                textPosition.add(textOffsetDir.multiplyScalar(textOffset));
                let textData = {
                  content: text.content,
                  id: text.id,
                  insertionPoint: textPosition,
                  offset: text.offset,
                  normalVector: alignmentData.normalVector,
                  directionVector: alignmentData.directionVector
                }; ///

                let normalVector = this.utils.initVector3(alignmentData.normalVector);
                let directionVector = this.utils.initVector3(alignmentData.directionVector);

                if (!normalVector.dot(directionVector) && normalVector.lengthSq() && directionVector.lengthSq()) {
                  let offsetVec = this.utils.initVector3(alignmentOffset);
                  textData.negativeOffset = textOffsetDir.clone().normalize().multiplyScalar(2 * textData.offset);
                  let [box, linePoints] = this.makeTextBoxWithLines(textData, textProps, font);
                  box.position.add(offsetVec);

                  if (alignments.textAlignment === 2 && alignments.textArrangement === 1) {
                    let linePtsArr = [];
                    linePoints.forEach(point => point.add(offsetVec));

                    for (let i = 0; i < linePoints.length - 1; i++) linePtsArr.push([linePoints[i], linePoints[i + 1]]);

                    isIntersection = this.intersectionDetection.checkCollision(linePtsArr, tempIntersectionTexts);
                    let intersectionObjects = this.GraphicsThree3D.getThreeSideFaceObjects(box);
                    tempIntersectionTexts.push(intersectionObjects);
                  }

                  textDataArr.push({
                    textData,
                    box
                  });
                }
              }
            }

            for (let i = 0; i < textDataArr.length; i++) {
              let textData = textDataArr[i].textData;
              let box = textDataArr[i].box;

              if (isIntersection && alignments.textAlignment === 2 && alignments.textArrangement === 1) {
                textData.offset = textDataArr[0].textData.offset;

                if (i % 2 === 1) {
                  textData.insertionPoint.sub(textData.negativeOffset);
                  box.position.sub(textData.negativeOffset);
                }
              }

              let intersectionObjects = this.GraphicsThree3D.getThreeSideFaceObjects(box);
              this.intersectionDetection.chainArr.push(intersectionObjects);
              let textMesh = this.GraphicsThree3D.drawText(textData, textProps, font);
              chain.add(textMesh);
            }
          }
        }

        chain.position.add(this.utils.initVector3(alignmentOffset));
      } else if (chainType === "arc") {
        let centerPoint = this.utils.initVector3(dimensionalChain.referencePoints[0]);
        let arcRadius = alignmentOffset.length(); // baseLine

        let rotationAngle = dimensionalChain.shape.rotationAngle;
        let rotationAxis = new this.THREE.Vector3(0, 0, 1);
        let shapeAxis = this.utils.initVector3(dimensionalChain.shape.rotationAxis).normalize();
        let quaternion = new this.THREE.Quaternion().setFromUnitVectors(shapeAxis, rotationAxis);
        alignmentOffset.applyQuaternion(quaternion);
        let offsetVectorNormalized = alignmentOffset.clone().normalize();
        let startAngle = -Math.acos(offsetVectorNormalized.x) + this.GraphicsThree3D.degreeToRadians(rotationAngle);
        let endAngle = -Math.acos(offsetVectorNormalized.x);
        let isClockwise = rotationAngle > 0;
        let curve = new this.THREE.EllipseCurve(0, 0, arcRadius, arcRadius, startAngle, endAngle, isClockwise ? true : false, 0);
        let curvePoints = curve.getPoints(50).map(point => new this.THREE.Vector3(point.x, point.y, 0));

        if (hasBaseline) {
          let ellipse = this.utils.drawConnectedLines(curvePoints, lineProps);
          chain.add(ellipse); ///

          let intersectionObjects = this.GraphicsThree3D.getThreeSideFaceObjects(ellipse);
          intersectionObjects.forEach(object => {
            object.lookAt(shapeAxis);
            object.position.add(centerPoint);
            object.updateMatrixWorld();
          });
          this.intersectionDetection.chainArr.push(intersectionObjects);
        }

        let pseudoReferencePoints = [curvePoints[0], curvePoints[curvePoints.length - 1]];
        pseudoReferencePoints.forEach((pseudoReferencePoint, i) => {
          // baseLineOverhang
          if (baseLineOverhang && baseLineOverhang.position) {
            let overhangLength = baseLineOverhang.length;
            let overHangPosition = baseLineOverhang.position;
            let pivotPoint = new this.THREE.Vector3(0, 0, 0);
            let overhangPoint;

            if (overHangPosition == 1) {
              overhangPoint = new this.THREE.Vector3(i == 0 ? overhangLength : -overhangLength, 0, 0);
            } else if (overHangPosition == 2) {
              overhangPoint = new this.THREE.Vector3(i == 0 ? 0 : -overhangLength, 0, 0);
            } else if (overHangPosition == 3) {
              overhangPoint = new this.THREE.Vector3(i == 0 ? overhangLength : 0, 0, 0);
            }

            if (!pivotPoint.equals(overhangPoint)) {
              let lineMesh = this.utils.drawConnectedLines([pivotPoint, overhangPoint], lineProps);
              lineMesh.rotateOnAxis(rotationAxis, i == 0 ? Math.PI / 2 + startAngle : Math.PI / 2 + endAngle);
              if (rotationAngle < 0) lineMesh.rotateOnAxis(rotationAxis, Math.PI);
              lineMesh.position.copy(pseudoReferencePoint);
              chain.add(lineMesh);
            }
          } // endLine


          if (endLine) {
            let offsetDir = offsetVectorNormalized.clone();
            if (i === 0) offsetDir.applyAxisAngle(rotationAxis, this.GraphicsThree3D.degreeToRadians(rotationAngle));
            let lineMesh = this.drawEndLine(offsetDir, endLine, lineProps, pseudoReferencePoint, true);
            chain.add(lineMesh);
          } // endSymbol


          if (endSymbol) {
            let endSymbolType = endSymbol.type;

            if (endSymbolType === 1 && endSymbol.size) {
              let circleRadius = endSymbol.size / 2;
              let circle = this.makeCircleWithEdges(circleRadius, areaMaterial, lineProps);
              circle.position.copy(pseudoReferencePoint);
              chain.add(circle);
            } else if (endSymbolType === 2) {
              let offsetDir = offsetVectorNormalized.clone();
              if (i === 0) offsetDir.applyAxisAngle(rotationAxis, this.GraphicsThree3D.degreeToRadians(rotationAngle));
              let lineMesh = this.drawEndSymbolLine(offsetDir, rotationAxis, pseudoReferencePoint, endSymbol, lineProps, true);
              chain.add(lineMesh);
            } else if (endSymbolType === 3) {
              let arrowRadius = endSymbol.size / 4;
              let arrowLength = endSymbol.size;
              let arrowMesh = this.makeArrow(arrowRadius, arrowLength, areaMaterial, lineProps);
              arrowMesh.rotateOnAxis(rotationAxis, i == 0 ? startAngle : Math.PI + endAngle);
              arrowMesh.position.copy(pseudoReferencePoint);
              chain.add(arrowMesh);
            } else if (endSymbolType === 4) {
              let arrowRadius = endSymbol.size / 2;
              let arrowSize = endSymbol.size;
              let arrowMesh = this.makeArrow(arrowRadius, arrowSize, areaMaterial, lineProps);
              arrowMesh.rotateOnAxis(rotationAxis, i == 0 ? startAngle : endAngle);
              arrowMesh.position.copy(pseudoReferencePoint);
              chain.add(arrowMesh);
            } else if (endSymbolType === 5) {
              let arrowRadius = endSymbol.size / 2;
              let arrowSize = endSymbol.size;
              let arrowMesh = this.makeArrow(arrowRadius, arrowSize, areaMaterial, lineProps);
              arrowMesh.rotateOnAxis(rotationAxis, i == 0 ? Math.PI + startAngle : Math.PI + endAngle);
              arrowMesh.position.copy(pseudoReferencePoint);
              chain.add(arrowMesh);
            }
          }
        }); // text

        let canDrawText = this.canDrawText(dimensionalChain);

        if (canDrawText) {
          let font = this.GraphicsThree3D.loadFont(textProps);

          for (let i = 0; i < texts.length; i++) {
            let textContent = texts[i].content;
            let textInsertionPoint = texts[i].insertionPoint;
            let referencePoint = dimensionalChain.referencePoints[0];

            if (textContent && textInsertionPoint && this.utils.checkVectorsEquality(textInsertionPoint, referencePoint)) {
              let textOffset = texts[i].offset;
              let offsetDir = new this.THREE.Vector3().copy(alignmentOffset);
              offsetDir.applyAxisAngle(rotationAxis, this.GraphicsThree3D.degreeToRadians(rotationAngle / 2));
              let textPosition = new this.THREE.Vector3().copy(offsetDir);
              offsetDir = offsetDir.normalize();
              textPosition.add(offsetDir.multiplyScalar(textOffset));

              if (alignments.textAlignment === 1) {
                let textMesh = this.makeTextSprite(textContent, textPosition, textProps);
                chain.add(textMesh);
              } else if (alignments.textAlignment === 2 && alignmentData.normalVector && alignmentData.directionVector) {
                let textData = {
                  content: texts[i].content,
                  id: texts[i].id,
                  insertionPoint: textPosition,
                  offset: texts[i].offset,
                  normalVector: alignmentData.normalVector,
                  directionVector: alignmentData.directionVector
                };
                let textMesh = this.GraphicsThree3D.drawText(textData, textProps, font);
                chain.add(textMesh); ///

                let normalVector = this.utils.initVector3(alignmentData.normalVector);
                let directionVector = this.utils.initVector3(alignmentData.directionVector);

                if (!normalVector.dot(directionVector) && normalVector.lengthSq() && directionVector.lengthSq()) {
                  let [box, linePoints] = this.makeTextBoxWithLines(textData, textProps, font);
                  let pivotObj = new this.THREE.Object3D();
                  pivotObj.attach(box);
                  pivotObj.lookAt(shapeAxis);
                  pivotObj.position.add(centerPoint);
                  pivotObj.updateMatrixWorld();
                  pivotObj.children.forEach(child => child.applyMatrix4(pivotObj.matrixWorld));
                  let intersectionObjects = this.GraphicsThree3D.getThreeSideFaceObjects(box);
                  this.intersectionDetection.chainArr.push(intersectionObjects);
                }
              }
            }
          }
        }

        chain.lookAt(shapeAxis);
        chain.position.add(centerPoint);
      }

      return chain;
    }

    makeTextBoxWithLines(text, textProps, font) {
      let normalVector = this.utils.initVector3(text.normalVector);
      let directionVector = this.utils.initVector3(text.directionVector);
      let textInsertionPoint = text.insertionPoint;
      let textGeo = this.makeTextGeoLight(text, textProps, font);
      textGeo.computeBoundingBox();
      let bboxMax = textGeo.boundingBox.max;
      let bboxMin = textGeo.boundingBox.min;
      let linePoints = [new this.THREE.Vector3(bboxMin.x, bboxMax.y, 0), new this.THREE.Vector3(bboxMin.x, bboxMin.y, 0), new this.THREE.Vector3(bboxMax.x, bboxMin.y, 0), new this.THREE.Vector3(bboxMax.x, bboxMax.y, 0)];
      let boxWidth = bboxMax.x - bboxMin.x;
      let boxHeight = bboxMax.y - bboxMin.y;
      let boxDepth = 0.1;
      let textMaterial = new this.THREE.MeshBasicMaterial({
        color: 0xff0000
      });
      let boxGeometry = new this.THREE.BoxGeometry(boxWidth, boxHeight, boxDepth);
      let textMeshBox = new this.THREE.Mesh(boxGeometry, textMaterial);
      this.GraphicsThree3D.alignObjectWithVectors(textMeshBox, textInsertionPoint, normalVector, directionVector);
      textMeshBox.updateMatrix();
      linePoints.forEach(point => point.applyMatrix4(textMeshBox.matrix));
      return [textMeshBox, linePoints];
    }

    textInsideChain(textData, startPoint, chainDirection, [minLength, maxLength]) {
      const TOLERANCE = 0.0001;
      let textInsertionPoint = textData.insertionPoint;
      let textContent = textData.content;
      let insideChain;

      if (textInsertionPoint) {
        let textInsertionVector = this.utils.initVector3(textInsertionPoint);
        let insertionLength = textInsertionVector.length();
        textInsertionVector.sub(startPoint).normalize();
        insideChain = textContent && insertionLength >= minLength && insertionLength <= maxLength && (chainDirection.dot(textInsertionVector) > 1 - TOLERANCE || !textInsertionVector.length());
      }

      return insideChain;
    }

    canDrawText(chainData) {
      let texts = chainData.components.texts;
      let alignments = chainData.alignments;
      let textProps = chainData.properties.text;
      return texts && textProps.type && textProps.style && alignments.textAlignment && alignments.textArrangement && textProps.size && textProps.heightWidthRatio;
    }

    makeTextGeoLight(text, textProps, font) {
      let textContent = text.content;
      let fontSize = textProps.size;
      let heightWidthRatio = textProps.heightWidthRatio;
      let textGeo = new this.THREE.TextBufferGeometry(textContent, {
        font: font,
        size: fontSize,
        height: 0.1,
        curveSegments: 1
      });
      textGeo.scale(heightWidthRatio, 1, 1);
      textGeo.center();
      return textGeo;
    }

    drawEndLine(offsetDir, lineData, lineProps, linePos, forArc = false) {
      let [ptOne, ptTwo] = this.getEndLinePts(lineData, offsetDir, forArc);
      let lineMesh = this.utils.drawConnectedLines([ptOne, ptTwo], lineProps);
      lineMesh.position.add(linePos);
      return lineMesh;
    }

    getEndLinePts(lineData, offsetDir, forArc = false) {
      let length = lineData.lenght;
      let overhang = lineData.overhang;
      if (forArc) length *= -1;else overhang *= -1;
      let ptOne = offsetDir.clone().multiplyScalar(length);
      let ptTwo = offsetDir.clone().multiplyScalar(overhang);
      return [ptOne, ptTwo];
    }

    drawEndSymbolCircle(radius, areaMaterial, lineProps, normal, position) {
      let circle = this.makeCircleWithEdges(radius, areaMaterial, lineProps);
      circle.lookAt(normal);
      circle.position.copy(position);
      return circle;
    }

    drawEndSymbolLine(offsetDir, normalVector, linePos, symbolData, lineProps, forArc = false) {
      let lineSize = symbolData.size;
      let ptOne = offsetDir.clone().multiplyScalar(lineSize / 2);
      let ptTwo = offsetDir.clone().multiplyScalar(-lineSize / 2);
      let rotation = Math.PI / 4;
      if (forArc) rotation *= -1;
      ptOne.applyAxisAngle(normalVector, rotation);
      ptTwo.applyAxisAngle(normalVector, rotation);
      let lineMesh = this.utils.drawConnectedLines([ptOne, ptTwo], lineProps);
      lineMesh.position.add(linePos);
      return lineMesh;
    }

    makeArrow(radius, height, areaMaterial, lineProps) {
      let arrowMesh;

      if (areaMaterial) {
        let pts = [new this.THREE.Vector2(0, 0), new this.THREE.Vector2(-radius, -height), new this.THREE.Vector2(radius, -height)];
        let arrowShape = new this.THREE.Shape(pts);
        let arrowGeo = new this.THREE.ExtrudeBufferGeometry(arrowShape, {
          depth: 0.1,
          bevelEnabled: false
        });
        arrowGeo.translate(0, 0, -0.05);
        arrowMesh = new this.THREE.Mesh(arrowGeo, areaMaterial);
        let linePoints = pts.map(point => new this.THREE.Vector3(point.x, point.y, 0));
        let line = this.utils.drawConnectedLines(linePoints, lineProps, true);
        arrowMesh.add(line);
        arrowMesh.renderOrder = 1;
      } else {
        arrowMesh = new this.THREE.Mesh(); // TODO: Might need better solution
      }

      return arrowMesh;
    }

    makeCircleWithEdges(radius, areaMaterial, lineProps, customParameters = null) {
      let circleGeo;

      if (customParameters) {
        let thetaStart = customParameters.thetaStart;
        let thetaEnd = customParameters.thetaEnd;
        circleGeo = new this.THREE.CircleGeometry(radius, 32, thetaStart, thetaEnd);
        let radiusTwo = customParameters.radiusTwo;
        circleGeo.applyMatrix4(new this.THREE.Matrix4().makeScale(1, radiusTwo / radius, 1));
      } else {
        circleGeo = new this.THREE.CircleGeometry(radius, 32);
      }

      let areaMesh = new this.THREE.Mesh(circleGeo, areaMaterial);
      let linePts = circleGeo.vertices.slice(1);
      let line = this.utils.drawConnectedLines(linePts, lineProps, true);
      areaMesh.add(line);
      return areaMesh;
    }

    makeTextSprite(textContent, textPosition, textProps) {
      let textColor = "rgba(" + textProps.color.red + ", " + textProps.color.green + ", " + textProps.color.blue + ", " + textProps.color.alpha + ")";
      let textMesh = new _threeSpritetext.default(textContent);
      textMesh.fontSize = 100;
      textMesh.textHeight = textProps.size;
      textMesh.geometry.scale(textProps.heightWidthRatio, 1, 1);
      textMesh.color = textColor;
      textMesh.renderOrder = 1;
      if (textProps.type == 1) textMesh.fontFace = "Arial";else if (textProps.type == 2) textMesh.fontFace = "Calibri";else if (textProps.type == 3) textMesh.fontFace = "Courier";else if (textProps.type == 4) textMesh.fontFace = "TimesNewRoman";

      if (textProps.style == 2 || textProps.style == 4) {
        textMesh.geometry.applyMatrix4(new this.THREE.Matrix4().set(1, 0.1, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1));
      }

      if (textProps.style == 3 || textProps.style == 4) {
        textMesh.fontWeight = "bold";
      }

      textMesh.position.copy(textPosition);
      return textMesh;
    }

    getChainType(dimensionalChain) {
      let typeOfChain = dimensionalChain.shape.type;
      let referencePoints = dimensionalChain.referencePoints;
      if (typeOfChain === 1 && referencePoints.length >= 2) return "linear";else if (typeOfChain === 2 && dimensionalChain.referencePoints.length === 1 && dimensionalChain.shape.rotationAngle && dimensionalChain.shape.rotationAxis && (dimensionalChain.shape.rotationAxis.x || dimensionalChain.shape.rotationAxis.y || dimensionalChain.shape.rotationAxis.z)) return "arc";
    }

    getChainOffsetVector(chainData, chainType, alignmentData) {
      let initOffset = alignmentData.offsetVector;
      let offsetDir = this.utils.initVector3(initOffset).normalize();
      let distance = chainData.alignments.dimensionalChainArrangement.distance;
      let offsetDistance = new this.THREE.Vector3().copy(offsetDir).multiplyScalar(distance);
      let offsetVector = new this.THREE.Vector3().copy(offsetDistance);
      let meshArr = this.intersectionDetection.meshArr;
      let chainArr = this.intersectionDetection.chainArr;
      let isIntersection;

      do {
        isIntersection = false;
        let linePtsArr = [];

        if (chainType === "linear") {
          let startPoint = this.utils.initVector3(chainData.referencePoints[0]).add(offsetVector);
          let endPoint = this.utils.initVector3(chainData.referencePoints[chainData.referencePoints.length - 1]).add(offsetVector);
          linePtsArr.push([startPoint, endPoint]);
        } else {
          let curvePoints = this.generateArcPoints(chainData, offsetVector);

          for (let i = 0; i < curvePoints.length - 1; i++) linePtsArr.push([curvePoints[i], curvePoints[i + 1]]);
        } ///


        let texts = chainData.components.texts;
        let textProps = chainData.properties.text;
        let referencePoints = chainData.referencePoints;
        let canDrawText = this.canDrawText(chainData);
        let font = canDrawText ? this.GraphicsThree3D.loadFont(textProps) : null;

        if (chainData.alignments.textAlignment === 2) {
          if (chainType === "linear") {
            let startPoint = this.utils.initVector3(referencePoints[0]);
            let endPoint = this.utils.initVector3(referencePoints[referencePoints.length - 1]);
            let chainDirection = new this.THREE.Vector3().copy(endPoint).sub(startPoint).normalize();

            if (canDrawText) {
              let startLength = startPoint.length();
              let endLength = endPoint.length();
              let maxLength = Math.max(startLength, endLength);
              let minLength = Math.min(startLength, endLength);
              texts.forEach(text => {
                let textInsideChain = this.textInsideChain(text, startPoint, chainDirection, [minLength, maxLength]);

                if (textInsideChain) {
                  let textInsertionPoint = text.insertionPoint;
                  let textOffset = text.offset;
                  let pointsDir = new this.THREE.Vector3().subVectors(endPoint, startPoint).normalize();
                  let offsetDir = this.utils.initVector3(initOffset).normalize();
                  let crossPointsOffset = new this.THREE.Vector3().crossVectors(pointsDir, offsetDir);
                  let textOffsetDir = new this.THREE.Vector3().crossVectors(crossPointsOffset, pointsDir).normalize();
                  let textPosition = this.utils.initVector3(textInsertionPoint);
                  textPosition.add(textOffsetDir.multiplyScalar(textOffset));
                  let textData = {
                    content: text.content,
                    id: text.id,
                    insertionPoint: textPosition,
                    offset: text.offset,
                    normalVector: alignmentData.normalVector,
                    directionVector: alignmentData.directionVector
                  };
                  let [box, linePoints] = this.makeTextBoxWithLines(textData, textProps, font);
                  linePoints.forEach(point => point.add(offsetVector));

                  for (let i = 0; i < linePoints.length - 1; i++) linePtsArr.push([linePoints[i], linePoints[i + 1]]);
                }
              });
            }
          } else {
            let rotationAxis = this.utils.initVector3(chainData.shape.rotationAxis);
            let rotationAngle = chainData.shape.rotationAngle;
            let centerPoint = this.utils.initVector3(chainData.referencePoints[0]);

            if (canDrawText) {
              texts.forEach(text => {
                let textContent = text.content;
                let textInsertionPoint = text.insertionPoint;
                let referencePoint = chainData.referencePoints[0];

                if (textContent && textInsertionPoint && this.utils.checkVectorsEquality(textInsertionPoint, referencePoint)) {
                  let textOffset = text.offset;
                  let offsetDir = new this.THREE.Vector3().copy(offsetVector);
                  offsetDir.applyAxisAngle(rotationAxis, this.GraphicsThree3D.degreeToRadians(rotationAngle / 2));
                  let textPosition = new this.THREE.Vector3().copy(offsetDir);
                  offsetDir = offsetDir.normalize();
                  textPosition.add(offsetDir.multiplyScalar(textOffset));
                  let textData = {
                    content: text.content,
                    id: text.id,
                    insertionPoint: textPosition,
                    offset: text.offset,
                    normalVector: alignmentData.normalVector,
                    directionVector: alignmentData.directionVector
                  };
                  let [box, linePoints] = this.makeTextBoxWithLines(textData, textProps, font);
                  linePoints.forEach(point => point.add(centerPoint));

                  for (let i = 0; i < linePoints.length - 1; i++) linePtsArr.push([linePoints[i], linePoints[i + 1]]);
                }
              });
            }
          }
        } ///


        isIntersection = this.intersectionDetection.checkCollision(linePtsArr, meshArr) || this.intersectionDetection.checkCollision(linePtsArr, chainArr);
        if (isIntersection) offsetVector.add(offsetDistance);
      } while (isIntersection);

      return offsetVector;
    }

    generateArcPoints(chainData, alignmentOffset) {
      const DETAIL = 64;
      let offsetVector = alignmentOffset.clone();
      let arcRadius = offsetVector.length();
      let rotationAngle = chainData.shape.rotationAngle;
      let rotationAxis = new this.THREE.Vector3(0, 0, 1);
      let shapeAxis = this.utils.initVector3(chainData.shape.rotationAxis).normalize();
      let quaternion = new this.THREE.Quaternion().setFromUnitVectors(shapeAxis, rotationAxis);
      offsetVector.applyQuaternion(quaternion);
      let offsetVectorNormalized = offsetVector.clone().normalize();
      let startAngle = -Math.acos(offsetVectorNormalized.x) + this.GraphicsThree3D.degreeToRadians(rotationAngle);
      let endAngle = -Math.acos(offsetVectorNormalized.x);
      let isClockwise = rotationAngle > 0;
      let curve = new this.THREE.EllipseCurve(0, 0, arcRadius, arcRadius, startAngle, endAngle, isClockwise ? true : false, 0);
      let numOfPoints = DETAIL * rotationAngle / 360;
      let curvePoints = curve.getPoints(numOfPoints).map(point => new this.THREE.Vector3(point.x, point.y, 0));
      quaternion.conjugate();
      curvePoints.forEach(point => {
        point.applyQuaternion(quaternion);
        point.add(this.utils.initVector3(chainData.referencePoints[0]));
      });
      return curvePoints;
    }

  }

  _exports.DimensionalChainLoader = DimensionalChainLoader;
});