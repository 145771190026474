define("m08-2020/controllers/application", ["exports", "three", "three/examples/jsm/controls/OrbitControls.js", "m08-2020/lib/ThreeModule", "m08-2020/lib/ThreeCSG", "m08-2020/lib/CSGMesh", "m08-2020/lib/FigureLoaders/ArrowLoader", "m08-2020/lib/FigureLoaders/DimensionalChainLoader", "m08-2020/lib/FigureLoaders/FastenerLoader", "m08-2020/lib/FigureLoaders/DrawingPlaneLoader", "jquery", "polybooljs", "@tweenjs/tween.js"], function (_exports, THREE, _OrbitControls, _ThreeModule, _ThreeCSG, _CSGMesh, _ArrowLoader, _DimensionalChainLoader, _FastenerLoader, _DrawingPlaneLoader, _jquery, _polybooljs, _tween) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  //Schaltet die Deprication Warnungen aus
  Ember.deprecate = function () {};

  var _default = Ember.Controller.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_obj = {
    version: "##version##",
    loggedIn: true,
    wuerth: false,
    swg: true,
    verbaende: false,
    intl: Ember.inject.service(),
    appController: Ember.inject.controller('application'),
    init: function () {
      this._super();
    },
    initialisierung: function () {
      var self = this;
    },

    loadAction() {
      (0, _jquery.default)('#file-input').trigger('click');
    },

    async openFileAction(file) {
      console.log("fileLoaded action ");
      var self = this;
      console.log("jetzt werden die werte gesetzt!");
      var content = JSON.parse(await this.getFileData(event));
      console.log('filecontent'); // console.log(content);

      console.log(content.drawingObjects); // console.log(content.drawingObjects.length);
      //
      // console.log(content.drawingObjects.findBy('id', "ZO1"));
      // console.log(content.drawingObjects.arrows);

      self.graphicsThree3D.clearScene();
      self.graphicsThree3D.drawCustom(content); // self.graphicsThree3D.drawObject(content);
      // self.graphicsThree3D.drawFastenerAsync();
    },

    getFileData: function (event) {
      var self = this; // getting a hold of the file reference

      var file = event.target.files[0];
      console.log("Dateiname: " + event.target.files[0].name); // setting up the reader

      var reader = new FileReader();
      reader.readAsText(file, 'UTF-8'); //return data string

      return new Promise(resolve => {
        reader.onload = readerEvent => {
          resolve(readerEvent.target.result);
        };
      });
    },
    jsonUrl: 'example.json',
    //common ember part
    x: "",
    y: "",
    z: "",
    placeholderX: "x",
    placeholderY: "y",
    placeholderZ: "z",
    graphicsThree3D: null,

    setXInteractive(variable, value) {
      this.get('model').application.objectAt(0).set(variable, value);
      var newX = Number(this.get('model').application.objectAt(0).get('x'));
      if (!this.isNumeric(newX)) newX = null;
      this.graphicsThree3D.highlightX(newX);
    },

    setYInteractive(variable, value) {
      this.get('model').application.objectAt(0).set(variable, value);
      var newY = Number(this.get('model').application.objectAt(0).get('y'));
      if (!this.isNumeric(newY)) newY = null;
      this.graphicsThree3D.highlightY(newY);
    },

    setZInteractive(variable, value) {
      this.get('model').application.objectAt(0).set(variable, value); //this.cube.geometry = new THREE.BoxGeometry(Number(this.get('model').application.objectAt(0).get('x')), Number(this.get('model').application.objectAt(0).get('y')), Number(this.get('model').application.objectAt(0).get('z')));

      var newZ = Number(this.get('model').application.objectAt(0).get('z'));
      if (!this.isNumeric(newZ)) newZ = null;
      this.graphicsThree3D.highlightZ(newZ); //   while(this.scene.children.length > 0){
      //     this.scene.remove(this.scene.children[0]);
      // }
      // this.meshArray=[];
      // this.xPosIndeX=0;
      // this.xPosIndeY=0;
      // //console.log(newZ);
      // if(newZ)
      // for (let i = 0; i < this.extrusionVectorZOfObjectsArray.length; i++) {
      //   this.extrusionVectorZOfObjectsArray[i] = newZ;
      // }
      // //var isInteractiveZ=newZ?false:true;
      //   this.AddShapesToScene(newZ,true);
    },

    clearXInteractive() {
      this.graphicsThree3D.clearXHighlight();
    },

    clearYInteractive() {
      this.graphicsThree3D.clearYHighlight();
    },

    clearZInteractive() {
      this.graphicsThree3D.clearZHighlight();
    },

    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },

    //get objects json and initialize three.js scene
    threejsscene: Ember.computed('', function () {
      const self = this;
      const CSG = (0, _CSGMesh.CsgFactory)(THREE);
      self.graphicsThree3D = new _ThreeModule.GraphicsThree3D(THREE, _OrbitControls.OrbitControls, _ThreeCSG.ThreeBSP, CSG, _polybooljs.default, _tween.default, {
        ArrowLoader: _ArrowLoader.ArrowLoader,
        DimensionalChainLoader: _DimensionalChainLoader.DimensionalChainLoader,
        FastenerLoader: _FastenerLoader.FastenerLoader,
        DrawingPlaneLoader: _DrawingPlaneLoader.DrawingPlaneLoader
      });
      let returnValue = self.graphicsThree3D.initializeScene();
      self.getAllJsonWithAjax(self);
      return returnValue;
    }),

    getAllJsonWithAjax(self) {
      _jquery.default.ajax({
        'async': false,
        type: "GET",
        url: self.jsonUrl
      }).then(function (response) {
        self.graphicsThree3D.drawCustom(response);
      }, function (error) {
        console.log(error);
      });
    },

    ChangePosition0() {
      this.graphicsThree3D.ChangeCameraPosition0(); // this.actionCamera = 1;
      // this.changePosition.x = -5;
      // this.changePosition.y = 5;
      // this.changePosition.z = 10;
    },

    ChangePosition1() {
      this.graphicsThree3D.ChangeCameraPosition1(); // this.actionCamera = 1;
      // this.changePosition.x = -10;
      // this.changePosition.y = 1;
      // this.changePosition.z = 0;
      // return;
      // if(this.yPosIndex!=0){
      //   while(this.scene.children.length > 0){
      //     this.scene.remove(this.scene.children[0]);
      // }
      // this.meshArray=[];
      //   this.AddShapesToScene();
      //   this.yPosIndex=0;
      // }
      // for (let i = 0; i < this.meshArray.length; i++) {
      //   this.meshArray[i].rotation.x+=Math.PI / 2;
      // }
      // if(this.xPosIndex==0){
      //   for (let i = 0; i < this.meshArray.length; i++) {
      //     this.meshArray[i].position.y+=this.meshPositionMAX_X/2 - this.extrusionVectorZOfObjectsArray[i]/2;
      //   }
      // }
      // else if(this.xPosIndex==1){
      //   for (let i = 0; i < this.meshArray.length; i++) {
      //     this.meshArray[i].position.y+=this.meshPositionMAX_Y/2 - this.meshPositionMAX_Y/4;
      //   }
      // }
      // else if(this.xPosIndex==2){
      //   for (let i = 0; i < this.meshArray.length; i++) {
      //     this.meshArray[i].position.y-=this.meshPositionMAX_X/2 - this.extrusionVectorZOfObjectsArray[i]/2;
      //   }
      // }
      // else if(this.xPosIndex==3){
      //   for (let i = 0; i < this.meshArray.length; i++) {
      //     this.meshArray[i].position.y = (this.meshPositionMAX_Y*-1)/2;
      //   }
      // }
      // this.xPosIndex++;
      // if(this.xPosIndex==4)
      // this.xPosIndex=0;
    },

    ChangePosition2() {
      this.graphicsThree3D.ChangeCameraPosition2(); // this.actionCamera = 1;
      // this.changePosition.x = 0;
      // this.changePosition.y = 10;
      // this.changePosition.z = 0;
    },

    ChangePosition3() {
      this.graphicsThree3D.ChangeCameraPosition3(); // this.actionCamera = 1;
      // this.changePosition.x = 0;
      // this.changePosition.y = 0;
      // this.changePosition.z = 10;
    },

    ToggleWireframe() {
      this.graphicsThree3D.ToggleWireframe(); // // var transparency=0.5;
      // this.isWireFrameOnly=!this.isWireFrameOnly;
      // while(this.scene.children.length > 0){
      //   this.scene.remove(this.scene.children[0]);
      // }
      // var light = new THREE.AmbientLight( 0xFFFFFF ); // soft white light
      //     this.scene.add(light);
      //   this.getAllJsonWithAjax(this);
      //   if(this.isWireFrameOnly)
      // transparency=0;
      //     this.materialWoodOptions.opacity=transparency;
      //     var materialWood = new THREE.MeshPhongMaterial(this.materialWoodOptions);
      //     for (let i = 0; i < this.meshArray.length; i++) {
      //       this.meshArray[i].material=materialWood;
      //     }
    },

    loadDrawingPlanes() {
      this.graphicsThree3D.DrawingPlaneLoader.loadDrawingPlanes();
    },

    SendBase64PNGToServer() {
      this.graphicsThree3D.SendBase64PNGToServer(); //   this.renderer.render( this.scene, this.camera ); // only export mesh1
      //   var strMime = "image/png";
      //   var imgData = this.renderer.domElement.toDataURL(strMime);
      //   // $.ajax({
      //   //   type: "POST",
      //   //   url: "https://localhost:44307/Home/ReceiveBase64PNG",
      //   //   data: { base64Png: imgData.replace("data:image/png;base64,","") }
      //   // });
      //  //download image
      //   var link = document.createElement('a');
      //   if (typeof link.download === 'string') {
      //     document.body.appendChild(link); //Firefox requires the link to be in the body
      //     link.download = 'grafikimg.png';
      //     link.href = imgData;
      //     link.click();
      //     document.body.removeChild(link); //remove the link when done
      //   } else {
      //     location.replace(uri);
      //   }
    } // @action
    // changecamerax(){
    //   this.camera.position.x+=0.1;
    //   this.controls.update();
    // },
    // @action
    // changecameray(){
    //   this.camera.position.y-=0.1;
    //   this.controls.update();
    // },


  }, (_applyDecoratedDescriptor(_obj, "loadAction", [_dec], Object.getOwnPropertyDescriptor(_obj, "loadAction"), _obj), _applyDecoratedDescriptor(_obj, "openFileAction", [_dec2], Object.getOwnPropertyDescriptor(_obj, "openFileAction"), _obj), _applyDecoratedDescriptor(_obj, "setXInteractive", [_dec3], Object.getOwnPropertyDescriptor(_obj, "setXInteractive"), _obj), _applyDecoratedDescriptor(_obj, "setYInteractive", [_dec4], Object.getOwnPropertyDescriptor(_obj, "setYInteractive"), _obj), _applyDecoratedDescriptor(_obj, "setZInteractive", [_dec5], Object.getOwnPropertyDescriptor(_obj, "setZInteractive"), _obj), _applyDecoratedDescriptor(_obj, "clearXInteractive", [_dec6], Object.getOwnPropertyDescriptor(_obj, "clearXInteractive"), _obj), _applyDecoratedDescriptor(_obj, "clearYInteractive", [_dec7], Object.getOwnPropertyDescriptor(_obj, "clearYInteractive"), _obj), _applyDecoratedDescriptor(_obj, "clearZInteractive", [_dec8], Object.getOwnPropertyDescriptor(_obj, "clearZInteractive"), _obj), _applyDecoratedDescriptor(_obj, "ChangePosition0", [_dec9], Object.getOwnPropertyDescriptor(_obj, "ChangePosition0"), _obj), _applyDecoratedDescriptor(_obj, "ChangePosition1", [_dec10], Object.getOwnPropertyDescriptor(_obj, "ChangePosition1"), _obj), _applyDecoratedDescriptor(_obj, "ChangePosition2", [_dec11], Object.getOwnPropertyDescriptor(_obj, "ChangePosition2"), _obj), _applyDecoratedDescriptor(_obj, "ChangePosition3", [_dec12], Object.getOwnPropertyDescriptor(_obj, "ChangePosition3"), _obj), _applyDecoratedDescriptor(_obj, "ToggleWireframe", [_dec13], Object.getOwnPropertyDescriptor(_obj, "ToggleWireframe"), _obj), _applyDecoratedDescriptor(_obj, "loadDrawingPlanes", [_dec14], Object.getOwnPropertyDescriptor(_obj, "loadDrawingPlanes"), _obj), _applyDecoratedDescriptor(_obj, "SendBase64PNGToServer", [_dec15], Object.getOwnPropertyDescriptor(_obj, "SendBase64PNGToServer"), _obj)), _obj)));

  _exports.default = _default;
});