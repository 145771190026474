define("m08-2020/lib/FigureLoaders/DrawingPlaneLoader", ["exports", "m08-2020/lib/Utils", "m08-2020/lib/FigureLoaders/DimensionalChainLoader", "svg-parser", "three/examples/jsm/renderers/SVGRenderer.js"], function (_exports, _Utils, _DimensionalChainLoader, _svgParser, _SVGRenderer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DrawingPlaneLoader = void 0;

  class DrawingPlaneLoader {
    constructor(GraphicsThree3D) {
      this.THREE = GraphicsThree3D.THREE;
      this.fontUrlArray = GraphicsThree3D.fontUrlArray;
      this.GraphicsThree3D = GraphicsThree3D;
      this.utils = new _Utils.Utils(GraphicsThree3D.THREE);
      this.lastPlaneID = ""; ///

      this.loadDrawingPlanes = this.loadDrawingPlanes.bind(this);
    }

    loadDrawingPlanes() {
      let jsonData = this.GraphicsThree3D.jsonData;
      let settingProps = jsonData.settings.drawingPlane.properties;
      let unitsData = jsonData.units;
      let drawingPlanes = jsonData.drawingPlanes;
      let units = this.utils.processUnits(unitsData);
      let dimensionalChainLoader = new _DimensionalChainLoader.DimensionalChainLoader(this.GraphicsThree3D);
      let dimensionalChains = jsonData.dimensionalChains;
      this.lastPlaneID = drawingPlanes[drawingPlanes.length - 1]["id"];
      drawingPlanes.forEach(drawingPlane => {
        let [cameraZoom, canvasWidth, canvasHeight] = this.getGraphicsParameters(this.GraphicsThree3D); // Components

        let drawingData = drawingPlane.components.drawing;
        let frameData = drawingPlane.components.frame;
        let freeTexts = drawingPlane.components.freeTexts;
        let scaleBarData = drawingPlane.components.scaleBar;
        let scaleTextBlockData = drawingPlane.components.scaleTextBlock;
        let unitTextBlockData = drawingPlane.components.unitTextBlock; // Properties

        let properties = drawingPlane.properties;
        let drawingProps = properties.drawing ? properties.drawing : settingProps.drawing;
        let frameProps = properties.frame ? properties.frame : settingProps.frame;
        let freeTextProps = properties.freeText ? properties.freeText : settingProps.freeText;
        let scaleBarProps = properties.scaleBar ? properties.scaleBar : settingProps.scaleBar;
        let scaleTextBlockProps = properties.scaleTextBlock ? properties.scaleTextBlock : settingProps.scaleTextBlock;
        let unitTextBlockProps = properties.unitTextBlock ? properties.unitTextBlock : settingProps.unitTextBlock; ///

        frameData = { ...frameData,
          drawingNormal: drawingData.normalVector,
          drawingVerticalVector: drawingData.verticalVector
        };

        if (drawingData && drawingProps) {
          this.drawDimensionalChains(dimensionalChainLoader, dimensionalChains, drawingData);
          this.checkWrongScale(frameData);
          this.makeDrawing(drawingData, drawingProps);

          if (frameData && frameProps) {
            let frame = this.makeFrame(frameData, frameProps, drawingData);
            if (freeTextProps && frameData) this.drawFreeTexts(freeTexts, freeTextProps, frameData, frame);
            if (scaleTextBlockData && scaleTextBlockProps) this.drawScaleTextBlock(scaleTextBlockData, scaleTextBlockProps, frameData, frame);
            if (unitTextBlockData && unitTextBlockProps) this.drawUnitTextBlock(unitTextBlockData, unitTextBlockProps, units, frameData, frame);
            if (scaleBarData && scaleBarProps) this.generateScaleBarObject(scaleBarData, scaleBarProps, units.length, frameData, frame);
          }
        }

        this.scaleCanvas(frameData, frameProps, this.GraphicsThree3D);
        let initialWireframeStatus = this.makeObjectFilled(this.GraphicsThree3D);
        let scale = this.getScale(frameData);
        let drawingComponents = {
          frameDataAndProps: {
            frameData,
            frameProps
          },
          freeTextDataAndProps: {
            freeTexts,
            freeTextProps
          },
          scaleTextBlockDataAndProps: {
            scaleTextBlockData,
            scaleTextBlockProps
          },
          unitTextBlockDataAndProps: {
            unitTextBlockData,
            unitTextBlockProps
          },
          scaleBarDataAndProps: {
            scaleBarData,
            scaleBarProps
          }
        };
        let dxfPlaneData = this.getDXFPlaneData(drawingPlane, drawingProps, scale, drawingComponents, units);
        this.downloadPNGandDXFAndReset(cameraZoom, canvasWidth, canvasHeight, dxfPlaneData);
        this.resetToInitialWireframeStatus(this.GraphicsThree3D, initialWireframeStatus);
      });
    }

    getDXFPlaneData(drawingPlane, drawingProps, scale, drawingComponents, units) {
      return {
        id: drawingPlane.id,
        output: drawingPlane.output,
        normal: drawingPlane.components.drawing.normalVector,
        scale,
        units,
        drawingComponents,
        visualisation: {
          drawingObject: drawingProps.visualisation.drawingObject,
          fastener: drawingProps.visualisation.fastener,
          arrow: drawingProps.visualisation.arrow
        },
        dxfFastenerVisualisation: { ...drawingProps.dxfFastenerVisualisation
        }
      };
    }

    checkWrongScale(frameData) {
      let scaleType = frameData.scaleType;
      if (scaleType < 1 || scaleType > 2) this.GraphicsThree3D.clearScene();else {
        let testScale = this.getScaleToFitScene(frameData, this.GraphicsThree3D);
        let checkScale = this.getScale(frameData);
        if (checkScale < testScale) this.GraphicsThree3D.clearScene();
      }
    }
    /**
     * Download PNG
     */


    downloadPNGandDXFAndReset(cameraZoom, canvasWidth, canvasHeight, drawingPlaneData) {
      let drawingOutput = drawingPlaneData.output;
      this.GraphicsThree3D.renderAllScenes(this.GraphicsThree3D);

      if (drawingOutput.dxf) {
        if (drawingPlaneData.id === this.lastPlaneID) this.GraphicsThree3D.dxfGenerator.downloadDXF2D("plane.dxf", drawingPlaneData);else this.GraphicsThree3D.dxfGenerator.generateBlockAndEntitySections(drawingPlaneData);
      }

      if (drawingOutput.image) this.GraphicsThree3D.downloadAsPNGImage("plane.png");
      this.GraphicsThree3D.clearScene();
      this.GraphicsThree3D.camera.zoom = cameraZoom;
      this.GraphicsThree3D.renderer.domElement.style.width = canvasWidth.toFixed(0) + "px";
      this.GraphicsThree3D.renderer.domElement.style.height = canvasHeight.toFixed(0) + "px";
      this.GraphicsThree3D.updateRendererAndCamera(this.GraphicsThree3D.renderer, this.GraphicsThree3D.camera);
      this.GraphicsThree3D.renderAllScenes(this.GraphicsThree3D);
      this.GraphicsThree3D.camera.up = new this.THREE.Vector3(0, 1, 0);
      this.GraphicsThree3D.drawCustom(this.GraphicsThree3D.jsonData);
    }

    makeObjectFilled(GraphicsThree3D) {
      let wireframeStatus = GraphicsThree3D.isWireFrameOnly;
      GraphicsThree3D.isWireFrameOnly = true;
      GraphicsThree3D.ToggleWireframe();
      return wireframeStatus;
    }

    resetToInitialWireframeStatus(GraphicsThree3D, wireframeStatus) {
      GraphicsThree3D.isWireFrameOnly = !wireframeStatus;
      GraphicsThree3D.ToggleWireframe();
    }

    getGraphicsParameters(GraphicsThree3D) {
      let cameraZoom = GraphicsThree3D.camera.zoom;
      let rendererSize = GraphicsThree3D.renderer.getSize(new this.THREE.Vector2());
      return [cameraZoom, rendererSize.x, rendererSize.y];
    }
    /**
     * Scale Canvas
     */


    scaleCanvas(frameData, frameProps, GraphicsThree3D) {
      let [frameWidth, frameRatio] = this.getCameraWidthAndRatio(frameData, frameProps);
      this.changeRendererSize(frameRatio, GraphicsThree3D);
      this.zoomCamera(GraphicsThree3D.camera, frameRatio, frameWidth);
    }

    getCameraWidthAndRatio(frameData, frameProps) {
      let lineWidth = frameProps.outerLine.thickness;
      let frameWidth = frameData.width + lineWidth;
      let frameHeight = frameData.height + lineWidth;
      let scale = this.getScale(frameData);
      let scaledWidth = frameWidth * scale;
      let frameRatio = frameWidth / frameHeight;
      return [scaledWidth, frameRatio];
    }

    changeRendererSize(frameRatio, GraphicsThree3D) {
      const IMG_WIDTH = 2000;
      let canvasSize = GraphicsThree3D.renderer.getSize(new this.THREE.Vector2());
      let canvasWidth = canvasSize.x;
      let canvasHeight = canvasSize.y;
      let canvasRatio = canvasWidth / canvasHeight;
      const QUALITY_SCALE = IMG_WIDTH / canvasWidth;
      GraphicsThree3D.renderer.setSize(QUALITY_SCALE * canvasWidth * frameRatio / canvasRatio, QUALITY_SCALE * canvasHeight);
    }

    zoomCamera(camera, frameRatio, frameWidth) {
      const MULTIPLIER = 16;
      const CAMERA_VIEW_SIZE = 1000;
      camera.left = -CAMERA_VIEW_SIZE * frameRatio / MULTIPLIER;
      camera.right = CAMERA_VIEW_SIZE * frameRatio / MULTIPLIER;
      camera.top = CAMERA_VIEW_SIZE / MULTIPLIER;
      camera.bottom = -CAMERA_VIEW_SIZE / MULTIPLIER;
      let cameraWidth = camera.right - camera.left;
      camera.zoom = cameraWidth / frameWidth;
      camera.updateProjectionMatrix();
    }

    drawDimensionalChains(dimensionalChainLoader, dimensionalChains, drawingData) {
      let dimensionalChainsVis = this.GraphicsThree3D.visualisationControlObjects.dimensionalChain;
      let drawingNormal = this.utils.initVector3(drawingData.normalVector).normalize();
      let clippingPlane;

      if (drawingData.type === 2) {
        let planeDistance = this.getClippingPlaneDistance(drawingData, drawingNormal);
        clippingPlane = new this.THREE.Plane(drawingNormal, planeDistance);
      }

      dimensionalChains.forEach(chain => {
        let view2dAlignments = chain.alignments.views2d; ///

        if (view2dAlignments) {
          view2dAlignments.forEach(alignment => {
            if (chain.alignments.textAlignment === 1) {
              let startPoint = this.utils.initVector3(chain.referencePoints[0]);
              let endPoint = this.utils.initVector3(chain.referencePoints[chain.referencePoints.length - 1]);
              let pointsDir = new this.THREE.Vector3().subVectors(endPoint, startPoint).normalize();
              alignment.normalVector = drawingNormal.clone().negate();
              alignment.directionVector = pointsDir;
            }
          }); ///

          let chainData = { ...chain
          };
          chainData.alignments = { ...chain.alignments
          };
          chainData.alignments.textAlignment = 2;
          view2dAlignments.forEach(alignment => {
            let normalVector = this.utils.initVector3(alignment.normalVector);
            let dotProdAbs = Math.abs(normalVector.dot(drawingNormal));

            if (dotProdAbs === 1) {
              dimensionalChainsVis.forEach(chain3D => this.GraphicsThree3D.scene.remove(chain3D));
              let chains = dimensionalChainLoader.drawMaskette(chainData, alignment);
              chains.forEach(ch => {
                if (drawingData.type === 1 || drawingData.type === 2 && this.chainOnPlane(ch, clippingPlane)) this.GraphicsThree3D.scene.add(ch);
              });
            }
          });
        }
      });
      this.GraphicsThree3D.centerScene();
      this.GraphicsThree3D.DimensionalChainIntersectionDetection.chainArr = [];
    }

    chainOnPlane(chain, plane) {
      let isOnPlane = true;
      chain.traverse(child => {
        if (plane.distanceToPoint(child.position) !== 0) isOnPlane = false;
      });
      return isOnPlane;
    }
    /**
     * ScaleBar
     */


    generateScaleBarObject(scaleBarData, scaleBarProps, unitsLength, frameData, frame) {
      let areaProps = scaleBarProps.area;
      let lineProps = scaleBarProps.line;
      let textProps = scaleBarProps.text;
      let normalVector = new this.THREE.Vector3(0, 0, 1);
      let directionVector = this.utils.initVector3(scaleBarData.directionVector).normalize();
      let insertionPoint = this.utils.initVector3(scaleBarData.insertionPoint);
      let [outerPoints, _] = this.getFramePoints(frameData);
      insertionPoint.add(outerPoints[0]);
      let scale = this.getScale(frameData);
      let scaleBarSize = {
        height: scaleBarData.height,
        segmentWidth: 1
      };
      let scaleBarObject = new this.THREE.Object3D();
      let scaleBarLine = this.drawScaleBarLine(scaleBarSize, lineProps);
      scaleBarObject.add(scaleBarLine);
      let scaleBarArea = this.drawScaleBarAreas(scaleBarSize, areaProps);
      scaleBarObject.add(scaleBarArea);
      this.drawScaleBarTexts(scaleBarData, scale, unitsLength, textProps, scaleBarObject);
      scaleBarObject = this.alignScaleBar(scaleBarObject, scaleBarData);
      this.utils.alignObjectWithVectors(scaleBarObject, normalVector, directionVector);
      scaleBarObject.position.add(insertionPoint);
      frame.add(scaleBarObject);
    }

    alignScaleBar(scaleBarObject, scaleBarData) {
      let relativePosition = scaleBarData.relativePosition;
      let alignedScaleBar = new this.THREE.Object3D();
      alignedScaleBar.add(scaleBarObject);
      let bbox = new this.THREE.Box3().setFromObject(scaleBarObject);
      this.GraphicsThree3D.dxfGenerator.drawingScaleBarBBox = bbox.clone();
      let bboxSize = bbox.getSize(new this.THREE.Vector3());
      let centerOffset = bbox.getCenter(new this.THREE.Vector3());
      scaleBarObject.children.forEach(child => child.position.sub(centerOffset));
      let alignmentOffset = this.getScaleBarAlignmentOffset(bboxSize, relativePosition);
      scaleBarObject.position.add(alignmentOffset);
      return alignedScaleBar;
    }

    getScaleBarAlignmentOffset(bboxSize, relativePosition) {
      let alignmentOffset = new this.THREE.Vector3(0, 0, 0);

      switch (relativePosition) {
        case 2:
          alignmentOffset.set(bboxSize.x / 2, bboxSize.y / 2, 0);
          break;

        case 3:
          alignmentOffset.set(bboxSize.x / 2, -bboxSize.y / 2, 0);
          break;

        case 4:
          alignmentOffset.set(-bboxSize.x / 2, -bboxSize.y / 2, 0);
          break;

        case 5:
          alignmentOffset.set(-bboxSize.x / 2, bboxSize.y / 2, 0);
          break;

        default:
          break;
      }

      return alignmentOffset;
    }

    drawScaleBarTexts(scaleBarData, scale, unitsLength, textProps, scaleBarObject) {
      const NUM_OF_SEGMENTS = 5;
      const SEGMENT_WIDTH = 1;
      const TEXT_RELATIVE_POSITION = 1;
      let decimalPlaces = scaleBarData.decimalPlaces;
      let distance = scaleBarData.distance;
      let height = scaleBarData.height;
      let textOffset = scaleBarData.textOffset;
      let scaleFactor = this.getScaleFactor(unitsLength);
      let heightOffset = -distance - textProps.size / 2;
      let textData = {
        content: "",
        normalVector: {
          x: 0,
          y: 0,
          z: 1
        },
        directionVector: {
          x: 1,
          y: 0,
          z: 0
        },
        insertionPoint: {
          x: 0,
          y: heightOffset,
          z: 0
        },
        relativePosition: TEXT_RELATIVE_POSITION
      };

      for (let i = 0; i <= NUM_OF_SEGMENTS; i++) {
        textData.content = (i * scale * scaleFactor).toFixed(decimalPlaces);
        if (i === 5 && unitsLength) textData.content += ` ${unitsLength}`;

        if (textOffset) {
          if (i % 2 === 1) textData.insertionPoint.y = height - heightOffset;else textData.insertionPoint.y = heightOffset;
        }

        textData.insertionPoint.x = i * SEGMENT_WIDTH;
        let font = this.GraphicsThree3D.loadFont(textProps);
        let text = this.GraphicsThree3D.drawText(textData, textProps, font);
        scaleBarObject.add(text);
      }
    }

    getScaleFactor(unit) {
      let factor = 1;

      switch (unit) {
        case "mm":
          factor *= 10;
          break;

        case "m":
          factor /= 100;
          break;

        case "km":
          factor /= 100000;
          break;

        default:
          break;
      }

      return factor;
    }

    drawScaleBarAreas(scaleBarSize, areaProps) {
      const NUM_OF_SEGMENTS = 5;
      let height = scaleBarSize.height;
      let segmentWidth = scaleBarSize.segmentWidth;
      let totalWidth = NUM_OF_SEGMENTS * segmentWidth;
      let geoOne = new this.THREE.PlaneGeometry(segmentWidth, height);
      let geoTwo = geoOne.clone();
      geoOne.translate(-segmentWidth, 0, 0);
      geoTwo.translate(segmentWidth, 0, 0);
      geoOne.merge(geoTwo);
      geoOne.translate(totalWidth / 2, height / 2, 0);
      let mat = this.utils.getMaterialFromColorProps(areaProps.color);
      let area = new this.THREE.Mesh(geoOne, mat);
      return area;
    }

    drawScaleBarLine(scaleBarSize, lineProps) {
      const NUM_OF_SEGMENTS = 5;
      let height = scaleBarSize.height;
      let segmentWidth = scaleBarSize.segmentWidth;
      let totalWidth = NUM_OF_SEGMENTS * segmentWidth;
      let pts = [new this.THREE.Vector3(0, 0, 0), new this.THREE.Vector3(totalWidth, 0, 0), new this.THREE.Vector3(totalWidth, height), new this.THREE.Vector3(0, height, 0)];
      let line = this.drawOutline(pts, lineProps, true);
      this.addPointsToVertices(lineProps.thickness, lineProps.color, pts, line);
      return line;
    }
    /**
     * UnitTextBlock
     */


    drawUnitTextBlock(unitTextBlockData, unitTextBlockProps, units, frameData, frame) {
      let font = this.GraphicsThree3D.loadFont(unitTextBlockProps);
      let [outerPoints, _] = this.getFramePoints(frameData);
      let zeroPoint = outerPoints[0];
      let content = this.generateUnitTextContent(unitTextBlockData, units);
      let data = this.generateTextData(content, unitTextBlockData, zeroPoint);
      let text = this.GraphicsThree3D.drawText(data, unitTextBlockProps, font);
      frame.add(text);
    }

    generateUnitTextContent(unitTextBlockData, units) {
      let startText = unitTextBlockData.startText;
      let endText = unitTextBlockData.endText;
      let separator = unitTextBlockData.separator;
      let content = "";
      content += startText;
      let unitValues = Object.values(units);
      unitValues.forEach((value, index) => {
        if (index && value) content += separator;
        if (value) content += value;
      });
      content += endText;
      return content;
    }
    /**
     * ScaleTextBlock
     */


    drawScaleTextBlock(scaleTextBlockData, scaleTextBlockProps, frameData, frame) {
      let font = this.GraphicsThree3D.loadFont(scaleTextBlockProps);
      let [outerPoints, _] = this.getFramePoints(frameData);
      let zeroPoint = outerPoints[0];
      let decimals = scaleTextBlockData.decimalPlaces;
      let scale = this.getScale(frameData);
      let content = `${scaleTextBlockData.text} ${scale.toFixed(decimals)}`;
      let data = this.generateTextData(content, scaleTextBlockData, zeroPoint);
      let text = this.GraphicsThree3D.drawText(data, scaleTextBlockProps, font);
      frame.add(text);
    }
    /**
     * FreeTexts
     */


    drawFreeTexts(freeTexts, textProps, frameData, frame) {
      let font = this.GraphicsThree3D.loadFont(textProps);
      let [outerPoints, _] = this.getFramePoints(frameData);
      let zeroPoint = outerPoints[0];
      freeTexts.forEach(textData => {
        let content = textData.content;
        let data = this.generateTextData(content, textData, zeroPoint);
        let text = this.GraphicsThree3D.drawText(data, textProps, font);
        frame.add(text);
      });
    }

    generateTextData(content, textData, zeroPoint) {
      let id = textData.id;
      let relativePosition = textData.relativePosition;
      let directionVector = this.utils.initVector3(textData.directionVector).normalize();
      let insertionPoint = this.utils.initVector3(textData.insertionPoint);
      insertionPoint.add(zeroPoint);
      let data = {
        content,
        id,
        relativePosition,
        directionVector: {
          x: directionVector.x,
          y: directionVector.y,
          z: directionVector.z
        },
        normalVector: {
          x: 0,
          y: 0,
          z: 1
        },
        insertionPoint: {
          x: insertionPoint.x,
          y: insertionPoint.y,
          z: insertionPoint.z
        }
      };
      return data;
    }
    /**
     * Frame
     */


    makeFrame(frameData, frameProps, drawingData) {
      let normalVector = this.utils.initVector3(drawingData.normalVector).normalize().negate();
      let verticalVector = this.utils.initVector3(drawingData.verticalVector).normalize().negate();
      let frame = this.generateFrameObject(frameData, frameProps);
      this.scaleFrame(frame, frameData);
      this.utils.alignObjectWithVectors(frame, normalVector, new this.THREE.Vector3().crossVectors(normalVector, verticalVector));
      frame.position.copy(this.getFramePosition(drawingData, this.GraphicsThree3D));
      this.GraphicsThree3D.scene.add(frame);
      return frame;
    }

    getScaleToFitScene(frameData, GraphicsThree3D) {
      let normalVec = this.utils.initVector3(frameData.drawingNormal);
      let upVec = this.utils.initVector3(frameData.drawingVerticalVector);
      let crossVec = new this.THREE.Vector3().crossVectors(normalVec, upVec);
      let bboxPoints = [new this.THREE.Vector3(GraphicsThree3D.objectMinX, GraphicsThree3D.objectMinY, GraphicsThree3D.objectMaxZ), new this.THREE.Vector3(GraphicsThree3D.objectMaxX, GraphicsThree3D.objectMinY, GraphicsThree3D.objectMaxZ), new this.THREE.Vector3(GraphicsThree3D.objectMaxX, GraphicsThree3D.objectMaxY, GraphicsThree3D.objectMaxZ), new this.THREE.Vector3(GraphicsThree3D.objectMinX, GraphicsThree3D.objectMaxY, GraphicsThree3D.objectMaxZ), new this.THREE.Vector3(GraphicsThree3D.objectMinX, GraphicsThree3D.objectMinY, GraphicsThree3D.objectMinZ), new this.THREE.Vector3(GraphicsThree3D.objectMaxX, GraphicsThree3D.objectMinY, GraphicsThree3D.objectMinZ), new this.THREE.Vector3(GraphicsThree3D.objectMaxX, GraphicsThree3D.objectMaxY, GraphicsThree3D.objectMinZ), new this.THREE.Vector3(GraphicsThree3D.objectMinX, GraphicsThree3D.objectMaxY, GraphicsThree3D.objectMinZ)];
      let projectedPoints = this.projectPointsOnPlane(bboxPoints, normalVec);
      let [verticalPoints, horizontalPoints] = this.getVerticalAndHorizontalPoints(projectedPoints, upVec, crossVec);
      let [maxWidth, maxHeight] = this.getMaxWidthHeightProjected(verticalPoints, horizontalPoints, upVec, crossVec);
      let frameWidth = frameData.width - frameData.border.left - frameData.border.right;
      let scale = maxWidth / frameWidth;
      return scale;
    }

    getMaxWidthHeightProjected(verticalPoints, horizontalPoints, upVec, crossVec) {
      let maxHeight = 0;
      let maxWidth = 0;
      const EPSILON = 0.0001;
      verticalPoints.up.forEach(upPoint => verticalPoints.down.forEach(downPoint => {
        let distance = upPoint.distanceTo(downPoint);
        let dirVec = downPoint.clone().sub(upPoint).normalize();
        if (distance > maxHeight && Math.abs(dirVec.dot(upVec)) > 1 - EPSILON) maxHeight = distance;
      }));
      horizontalPoints.right.forEach(rightPoint => horizontalPoints.left.forEach(leftPoint => {
        let distance = rightPoint.distanceTo(leftPoint);
        let dirVec = rightPoint.clone().sub(leftPoint).normalize();
        if (distance > maxWidth && Math.abs(dirVec.dot(crossVec)) > 1 - EPSILON) maxWidth = distance;
      }));
      return [maxWidth, maxHeight];
    }

    projectPointsOnPlane(pointsArr, normalVec) {
      let projectedPoints = [];
      pointsArr.forEach(point => {
        projectedPoints.push(point.projectOnPlane(normalVec));
      });
      return projectedPoints;
    }

    getVerticalAndHorizontalPoints(pointsArr, upVec, crossVec) {
      let verticalPoints = {
        up: [],
        down: []
      };
      let horizontalPoints = {
        right: [],
        left: []
      };
      pointsArr.forEach(point => {
        if (point.dot(upVec) > 0) verticalPoints.up.push(point);else verticalPoints.down.push(point);
        if (point.dot(crossVec) > 0) horizontalPoints.right.push(point);else horizontalPoints.left.push(point);
      });
      return [verticalPoints, horizontalPoints];
    }

    getScale(frameData) {
      let scaleType = frameData.scaleType;
      let scale = 1;

      if (scaleType === 1) {
        scale = this.getScaleToFitScene(frameData, this.GraphicsThree3D);
      } else if (scaleType === 2) {
        let scaleList = frameData.scaleList;
        let minimumScale = this.getScaleToFitScene(frameData, this.GraphicsThree3D);
        let maximumZoom = Infinity;
        scaleList.forEach(scaleStr => {
          let scaleParams = scaleStr.split(":");
          let newScale = parseFloat(scaleParams[1]) / parseFloat(scaleParams[0]);

          if (newScale >= minimumScale && scale < maximumZoom) {
            scale = newScale;
            maximumZoom = scale;
          }
        });
      }

      return scale;
    }

    scaleFrame(frame, frameData) {
      let scale = this.getScale(frameData);
      frame.scale.set(scale, scale, scale);
    }

    getFramePosition(drawingData, GraphicsThree3D) {
      let normalVector = this.utils.initVector3(drawingData.normalVector).normalize();
      let framePos;

      if (drawingData.positionVector) {
        let positionVec = this.utils.initVector3(drawingData.positionVector);
        let objMidVec = GraphicsThree3D.objectMidVector.clone();
        let posDir = positionVec.clone().normalize();
        let posDist = positionVec.length();
        let proj = objMidVec.dot(posDir);

        if (proj > posDist) {
          let delta = proj - posDist;
          let proportion = delta / proj;
          framePos = objMidVec.sub(normalVector.clone().multiplyScalar(proportion));
        } else {
          let positionDirection = positionVec.clone().normalize();
          let planeDistance = positionVec.length();
          if (normalVector.dot(positionDirection) > 0) planeDistance *= -1;
          let viewPlane = new this.THREE.Plane(normalVector, planeDistance);
          let projectedPoint = new this.THREE.Vector3();
          viewPlane.projectPoint(objMidVec, projectedPoint);
          framePos = projectedPoint;
        }
      } else {
        let midVec = GraphicsThree3D.objectMidVector.clone();
        let bboxPoints = [new this.THREE.Vector3(GraphicsThree3D.objectMinX, GraphicsThree3D.objectMinY, GraphicsThree3D.objectMaxZ), new this.THREE.Vector3(GraphicsThree3D.objectMaxX, GraphicsThree3D.objectMinY, GraphicsThree3D.objectMaxZ), new this.THREE.Vector3(GraphicsThree3D.objectMaxX, GraphicsThree3D.objectMaxY, GraphicsThree3D.objectMaxZ), new this.THREE.Vector3(GraphicsThree3D.objectMinX, GraphicsThree3D.objectMaxY, GraphicsThree3D.objectMaxZ), new this.THREE.Vector3(GraphicsThree3D.objectMinX, GraphicsThree3D.objectMinY, GraphicsThree3D.objectMinZ), new this.THREE.Vector3(GraphicsThree3D.objectMaxX, GraphicsThree3D.objectMinY, GraphicsThree3D.objectMinZ), new this.THREE.Vector3(GraphicsThree3D.objectMaxX, GraphicsThree3D.objectMaxY, GraphicsThree3D.objectMinZ), new this.THREE.Vector3(GraphicsThree3D.objectMinX, GraphicsThree3D.objectMaxY, GraphicsThree3D.objectMinZ)].map(point => point.sub(midVec));
        let viewPlane = new this.THREE.Plane(normalVector);
        let minDistance = 0;
        bboxPoints.forEach(point => {
          let distance = viewPlane.distanceToPoint(point);
          if (distance < minDistance) minDistance = distance;
        });
        framePos = midVec.add(normalVector.clone().multiplyScalar(minDistance));
      }

      return framePos;
    }

    generateFrameObject(frameData, frameProps) {
      let innerLineProps = frameProps.innerLine;
      let outerLineProps = frameProps.outerLine;
      let areaProps = frameProps.area;
      let [outerPoints, innerPoints] = this.getFramePoints(frameData);
      let frameObject = new this.THREE.Object3D();
      let plane = this.drawPlane(frameData, areaProps);
      frameObject.add(plane);
      let outerLine = this.drawOutline(outerPoints, outerLineProps, true);
      frameObject.add(outerLine);
      let innerLine = this.drawOutline(innerPoints, innerLineProps, true);
      frameObject.add(innerLine);
      this.drawFrameVertexPoints(outerPoints, innerPoints, frameProps, frameObject);
      return frameObject;
    }

    drawOutline(points, lineProps) {
      let firstLine = new this.THREE.Mesh();

      if (lineProps.thickness && lineProps.color.alpha && (lineProps.type || lineProps.type === undefined)) {
        if (lineProps.type === 1 || lineProps.type === undefined || lineProps.factor === 0 && lineProps.type !== null && lineProps.type !== 0) {
          let lineMeshArray = [];

          for (let i = 0; i < points.length - 1; i++) {
            if (points[i].distanceTo(points[i + 1]) > 0) {
              let lineMesh = this.utils.drawLine(points[i], points[i + 1], lineProps, true);
              lineMeshArray.push(lineMesh);
            }
          }

          if (points[points.length - 1].distanceTo(points[0]) > 0) {
            let lineMesh = this.utils.drawLine(points[points.length - 1], points[0], lineProps, true);
            lineMeshArray.push(lineMesh);
          }

          firstLine = lineMeshArray.length > 0 ? lineMeshArray.splice(0, 1)[0] : new this.THREE.Mesh();
          let lineMaterial = firstLine.material;
          firstLine.updateMatrixWorld();
          firstLine.geometry.applyMatrix4(firstLine.matrixWorld);

          for (let i = 0; i < lineMeshArray.length; i++) {
            lineMeshArray[i].updateMatrixWorld();
            lineMeshArray[i].geometry.applyMatrix4(lineMeshArray[i].matrixWorld);
            firstLine.geometry.merge(lineMeshArray[i].geometry);
          }

          firstLine = new this.THREE.Mesh(firstLine.geometry, lineMaterial);
        }
      }

      return firstLine;
    }

    getFramePoints(frameData) {
      let width = frameData.width;
      let height = frameData.height;
      let borderLeft = frameData.border.left;
      let borderRight = frameData.border.right;
      let borderTop = frameData.border.top;
      let borderBot = frameData.border.bottom;
      let outerPoints = [new this.THREE.Vector3(-width / 2, -height / 2, 0), new this.THREE.Vector3(width / 2, -height / 2, 0), new this.THREE.Vector3(width / 2, height / 2, 0), new this.THREE.Vector3(-width / 2, height / 2, 0)];
      let innerPoints = [new this.THREE.Vector3(-width / 2 + borderLeft, -height / 2 + borderBot, 0), new this.THREE.Vector3(width / 2 - borderRight, -height / 2 + borderBot, 0), new this.THREE.Vector3(width / 2 - borderRight, height / 2 - borderTop, 0), new this.THREE.Vector3(-width / 2 + borderLeft, height / 2 - borderTop, 0)];
      return [outerPoints, innerPoints];
    }

    drawFrameVertexPoints(outerPoints, innerPoints, frameProps, frameObject) {
      let outerColor = frameProps.outerLine.color;
      let innerColor = frameProps.innerLine.color;
      let outerSize = frameProps.outerLine.thickness;
      let innerSize = frameProps.innerLine.thickness;
      this.addPointsToVertices(outerSize, outerColor, outerPoints, frameObject);
      this.addPointsToVertices(innerSize, innerColor, innerPoints, frameObject);
    }

    addPointsToVertices(size, color, points, frameObject) {
      let material = this.utils.getMaterialFromColorProps(color);
      points.forEach(point => {
        let mesh = this.drawPoint(point, size, material);
        frameObject.add(mesh);
      });
    }

    drawPoint(position, size, material) {
      let geometry = new this.THREE.BoxBufferGeometry(size, size, size);
      let mesh = new this.THREE.Mesh(geometry, material);
      mesh.position.copy(position);
      return mesh;
    }

    drawPlane(frameData, areaProps) {
      let width = frameData.width;
      let height = frameData.height;
      let planeWidth = width;
      let planeHeight = height;
      let planeGeo = new this.THREE.PlaneBufferGeometry(planeWidth, planeHeight);
      let planeMat = this.utils.getMaterialFromColorProps(areaProps.color);
      planeMat.depthWrite = false;
      let plane = new this.THREE.Mesh(planeGeo, planeMat);
      return plane;
    }
    /**
     * Drawing
     */


    makeDrawing(drawingData, drawingProps) {
      if (drawingData.type === 1 || drawingData.type === 2) {
        let normalVector = this.utils.initVector3(drawingData.normalVector).normalize();
        let verticalVector = this.utils.initVector3(drawingData.verticalVector).normalize();
        let scene = this.GraphicsThree3D.scene;
        this.positionCamera(normalVector.clone().negate(), verticalVector);

        if (drawingData.type === 2) {
          let planeDistance = this.getClippingPlaneDistance(drawingData, normalVector);
          let clippingPlane = new this.THREE.Plane(normalVector, planeDistance);
          this.clipSceneObjects(scene, clippingPlane);
        }

        this.changeObjectsTransparency(this.GraphicsThree3D.transparencyControlObjects, drawingProps.transparency);
        this.changeObjectsVisualisation(this.GraphicsThree3D.visualisationControlObjects, drawingProps.visualisation);
      }
    }

    positionCamera(normalVector, verticalVector) {
      let camera = this.GraphicsThree3D.camera;
      let midVector = this.GraphicsThree3D.objectMidVector.clone();
      let cameraDistance = 150;
      let cameraPos = normalVector.clone().multiplyScalar(cameraDistance).add(midVector);
      let upGenObj = new this.THREE.Object3D();
      let upVector = this.GraphicsThree3D.getMeshUpVector(upGenObj, normalVector.clone());
      let sceneRotAngle = upVector.angleTo(verticalVector);
      camera.position.set(0, 0, 0);
      camera.lookAt(new this.THREE.Vector3(0, 0, 1));
      camera.rotation.z = sceneRotAngle;
      camera.up = verticalVector;
      camera.position.copy(cameraPos);
      camera.lookAt(midVector);
      camera.zoom = 1;
      camera.updateProjectionMatrix();
    }

    getClippingPlaneDistance(drawingData, normalVector) {
      let positionVector = this.utils.initVector3(drawingData.positionVector);
      let positionDirection = positionVector.clone().normalize();
      let planeDistance = positionVector.length();
      if (normalVector.dot(positionDirection) > 0) planeDistance *= -1;
      return planeDistance;
    }

    changeObjectsTransparency(objects, transparencyProps) {
      for (let [key, value] of Object.entries(objects)) {
        value.forEach(mesh => {
          let material = mesh.material;
          let transparency = transparencyProps[key] / 255.0;
          if (material.length) material.forEach(mat => mat.opacity = transparency);else material.opacity = transparency;
        });
      }
    }

    changeObjectsVisualisation(objects, visualisationProps) {
      for (let [key, value] of Object.entries(objects)) {
        value.forEach(object => {
          let visualisation = visualisationProps[key];
          object.visible = visualisation;
        });
      }
    }

    clipSceneObjects(scene, clippingPlane) {
      let objectIDs = {};
      scene.traverse(object => {
        if (object.type === "Mesh" || object.type === "LineLoop") {
          let material = object.material;
          this.clipMaterial(material, clippingPlane);
          if (!object.isDrawingObject) this.clipMaterialBack(material, clippingPlane);
          if (object.type === "Mesh") this.cutMesh(object, objectIDs, clippingPlane);
        }
      });
    }

    clipMaterial(material, plane) {
      if (material.length) material.forEach(mat => this.addClippingPlane(mat, plane));else this.addClippingPlane(material, plane);
    }

    addClippingPlane(material, plane) {
      if (material.clippingPlanes) material.clippingPlanes.push(plane);else material.clippingPlanes = [plane];
    }

    clipMaterialBack(material, plane) {
      const OFFSET = 1;
      let backPlane = plane.clone();
      backPlane.normal.negate();
      backPlane.constant = -backPlane.constant + OFFSET;
      material.clippingPlanes.push(backPlane);
    }

    cutMesh(mesh, ids, plane) {
      if (mesh.isDrawingObject) {
        const ERASER_OFFSET = 1;
        let eraserSize = 500;

        if (!(mesh.uuid in ids)) {
          ids[mesh.uuid] = 1;
          let eraserPos = plane.normal.clone().multiplyScalar(-plane.constant + eraserSize / 2 + ERASER_OFFSET);
          let eraserMesh = this.utils.makeTestMesh(eraserPos, eraserSize);
          eraserMesh.updateMatrix();
          let meshCSG = this.GraphicsThree3D.CSG.fromMesh(mesh);
          let eraserCSG = this.GraphicsThree3D.CSG.fromMesh(eraserMesh);
          let subCSG = meshCSG.subtract(eraserCSG);
          mesh.geometry = this.GraphicsThree3D.CSG.toMesh(subCSG, new this.THREE.Matrix4()).geometry;
        }
      }
    }

    getStencilMaterials() {
      // PASS 1
      // everywhere that the back faces are visible (clipped region) the stencil
      // buffer is incremented by 1.
      let backFaceStencilMat = new this.THREE.MeshBasicMaterial();
      backFaceStencilMat.depthWrite = false;
      backFaceStencilMat.depthTest = false;
      backFaceStencilMat.colorWrite = false;
      backFaceStencilMat.stencilWrite = true;
      backFaceStencilMat.stencilFunc = this.THREE.AlwaysStencilFunc;
      backFaceStencilMat.side = this.THREE.BackSide;
      backFaceStencilMat.stencilFail = this.THREE.IncrementWrapStencilOp;
      backFaceStencilMat.stencilZFail = this.THREE.IncrementWrapStencilOp;
      backFaceStencilMat.stencilZPass = this.THREE.IncrementWrapStencilOp; // PASS 2
      // everywhere that the front faces are visible the stencil
      // buffer is decremented back to 0.

      let frontFaceStencilMat = new this.THREE.MeshBasicMaterial();
      frontFaceStencilMat.depthWrite = false;
      frontFaceStencilMat.depthTest = false;
      frontFaceStencilMat.colorWrite = false;
      frontFaceStencilMat.stencilWrite = true;
      frontFaceStencilMat.stencilFunc = this.THREE.AlwaysStencilFunc;
      frontFaceStencilMat.side = this.THREE.FrontSide;
      frontFaceStencilMat.stencilFail = this.THREE.DecrementWrapStencilOp;
      frontFaceStencilMat.stencilZFail = this.THREE.DecrementWrapStencilOp;
      frontFaceStencilMat.stencilZPass = this.THREE.DecrementWrapStencilOp; // PASS 3
      // draw the plane everywhere that the stencil buffer != 0, which will
      // only be in the clipped region where back faces are visible.

      let planeStencilMat = new this.THREE.MeshNormalMaterial();
      planeStencilMat.stencilWrite = true;
      planeStencilMat.stencilRef = 0;
      planeStencilMat.stencilFunc = this.THREE.NotEqualStencilFunc;
      planeStencilMat.stencilFail = this.THREE.ReplaceStencilOp;
      planeStencilMat.stencilZFail = this.THREE.ReplaceStencilOp;
      planeStencilMat.stencilZPass = this.THREE.ReplaceStencilOp;
      return [backFaceStencilMat, frontFaceStencilMat, planeStencilMat];
    }

  }

  _exports.DrawingPlaneLoader = DrawingPlaneLoader;

  function saveSVG(GraphicsThree3D) {
    let rendererSize = GraphicsThree3D.renderer.getSize(new GraphicsThree3D.THREE.Vector2());
    var rendererSVG = new _SVGRenderer.SVGRenderer();
    rendererSVG.setSize(rendererSize.x, rendererSize.y);
    rendererSVG.render(GraphicsThree3D.scene, GraphicsThree3D.camera);
    console.log(rendererSVG.domElement);
    var XMLS = new XMLSerializer();
    var svgfile = XMLS.serializeToString(rendererSVG.domElement);
    console.log(svgfile);
    var svgData = svgfile;
    var preface = '<?xml version="1.0" standalone="no"?>\r\n';
    var svgBlob = new Blob([preface, svgData], {
      type: "image/svg+xml;charset=utf-8"
    });
    var svgUrl = URL.createObjectURL(svgBlob);
    var downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = "drawing.svg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink); // let parsed = parse(svgfile);
    // console.log(parsed)
    // let data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(parsed))
    // let link = document.createElement("a");
    // link.href = "data: " + data;
    // link.download = "svg_data.json";
    // link.click();
    // let testMesh = this.utils.makeTestMesh(new this.THREE.Vector3(), 10);
    // let exporter = new STLExporter();
    // let stlData = exporter.parse(testMesh, { binary: true });
    // console.log(stlData)
    // // let link = document.createElement("a");
    // // link.style.display = "none";
    // // document.body.appendChild(link);
    // let blob = new Blob( [stlData], {type: "application/octet-stream"} );
    // // link.href = URL.createObjectURL(blob);
    // // link.download = "testBox.stl";
    // // link.click();
    // let deserializedStl = jscadIO.deserializers.stl(stlData, blob, {
    //   output: "csg"
    // });
    // let dxfStringArray = jscadIO.dxfSerializer.serialize(deserializedStl);
    // console.log(dxfStringArray)
  }
});