define("m08-2020/lib/FigureLoaders/DrawingObjectComponents/Polygon", ["exports", "m08-2020/lib/ThreeExtensions/CustomEllipseCurve", "m08-2020/lib/ThreeExtensions/RotatedGeometry"], function (_exports, _CustomEllipseCurve, _RotatedGeometry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Polygon = void 0;

  class Polygon {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.THREE = GraphicsThree3D.THREE;
      this.utils = GraphicsThree3D.utils;
      this.CSG = GraphicsThree3D.CSG;
      this.WireframeGenerator = GraphicsThree3D.WireframeGenerator;
    }

    drawPolygon(polygonData, propertiesData) {
      let polygon;
      let isPlanar = this.polygonIsPlanar(polygonData);

      if (polygonData.objectGeneration.type === 1 || isPlanar) {
        polygon = this.drawPolygonPlanar(polygonData);
        polygon.generationType = "area";
      } else if (polygonData.objectGeneration.type === 2) {
        if (!isPlanar) {
          polygon = this.drawPolygonExtruded(polygonData);
          polygon.generationType = "volume";
        } else {
          polygon = this.drawPolygonExtrudedPlanar(polygonData);
          polygon.generationType = "area";
        }
      } else {
        if (isPlanar) {
          polygon = this.drawPolygonRotatedPlanar(polygonData);
          polygon.generationType = "area";
        } else {
          polygon = this.drawPolygonRotated(polygonData, propertiesData);
          polygon.generationType = "volume";
        }
      }

      return polygon;
    }

    drawPolygonPlanar(polygonData) {
      let polygonDepth = 0.01;
      let extrusionVector = new this.THREE.Vector3(0, 0, polygonDepth);
      let extrusionNormalized = extrusionVector.clone().normalize();
      let pts = this.getPolygonPlanarPoints(polygonData);
      let [midVector, eulerOne, eulerTwo] = this.GraphicsThree3D.getRotationParameters(pts, extrusionNormalized);
      pts.forEach(point => {
        // this.GraphicsThree3D.wireframePoints.push(point.clone());
        point.sub(midVector);
        point.applyEuler(eulerOne);
      });
      let shapePts = pts.map(point => new this.THREE.Vector2(point.x, point.y));
      let shape = new this.THREE.Shape(shapePts);
      extrusionVector.applyEuler(eulerOne);
      extrusionNormalized.applyEuler(eulerOne); // extrusionVector.z *= -1;

      let extrudeSettings = {
        depth: extrusionVector.z,
        bevelEnabled: false
      };
      let geometry = new this.THREE.ExtrudeGeometry(shape, extrudeSettings); // geometry.translate(0, 0, -extrusionVector.z);

      let polygon = new this.THREE.Mesh(geometry);
      polygon.position.add(midVector);
      polygon.updateMatrix();
      polygon.setRotationFromEuler(eulerTwo);
      return polygon;
    }

    drawPolygonExtruded(polygonData) {
      let extrusionVector = this.utils.initVector3(polygonData.objectGeneration.extrusionVector);
      let extrusionNormalized = extrusionVector.clone().normalize();
      let pts = this.getPolygonPlanarPoints(polygonData);
      let [midVector, eulerOne, eulerTwo, crossVector] = this.GraphicsThree3D.getRotationParameters(pts, extrusionNormalized);
      pts.forEach(point => {
        point.sub(midVector);
        point.applyEuler(eulerOne);
      });
      extrusionVector.applyEuler(eulerOne);
      let skewParams = this.utils.getSkewParams(extrusionVector);
      let polygonDepth = extrusionVector.z;
      let shapePts = pts.map(point => new this.THREE.Vector2(point.x, point.y));
      let geometry = this.GraphicsThree3D.makeExtrudeGeoRaw(shapePts, polygonDepth); // TODO: I am not sure this logic is correct

      if (crossVector.dot(new this.THREE.Vector3(0, 0, -1)) > 0) {
        geometry.translate(0, 0, -polygonDepth);
      }

      this.utils.skewExtrudedGeometry(geometry, skewParams);
      let polygon = new this.THREE.Mesh(geometry);
      polygon.position.add(midVector);
      polygon.setRotationFromEuler(eulerTwo);
      polygon.updateMatrix();
      return polygon;
    }

    drawPolygonExtrudedPlanar(polygonData) {
      let polygonDepth = 0.01;
      let areaExtrusion = this.utils.initVector3(polygonData.objectGeneration.extrusionVector);
      let extrusionVector = new this.THREE.Vector3(0, 0, polygonDepth);
      let extrusionNormalized = extrusionVector.clone().normalize();
      let polygonPoints = this.getPolygonPlanarPoints(polygonData);
      let [midVector, eulerOne, eulerTwo] = this.GraphicsThree3D.getRotationParameters(polygonPoints, extrusionNormalized);
      let epsilon = 0.1;
      let centerPoint = this.GraphicsThree3D.getPointsCenter3D(polygonPoints);
      let planeNormal = areaExtrusion.clone().normalize();
      let plane = new this.THREE.Plane(planeNormal);
      let pivotDistance = plane.distanceToPoint(centerPoint);
      let unchangedPoints = [];
      let unchangedAddition = [];
      let extrudePoints = [];
      let beforeIntersection = true;

      for (let i = 0; i < polygonPoints.length; i++) {
        let point = polygonPoints[i].clone();
        let distanceToPlane = plane.distanceToPoint(point);

        if (distanceToPlane < pivotDistance - epsilon) {
          if (beforeIntersection) unchangedPoints.push(point);else unchangedAddition.push(point);
        } else if (distanceToPlane > pivotDistance + epsilon) {
          extrudePoints.push(point);
          beforeIntersection = false;
        } else {
          if (beforeIntersection) unchangedPoints.push(point);else unchangedAddition.push(point);
          extrudePoints.push(point);
          beforeIntersection = false;
        }
      }

      extrudePoints.forEach(point => point.add(areaExtrusion));
      unchangedPoints.push(...extrudePoints);
      if (unchangedPoints.length > extrudePoints.length) unchangedAddition.reverse();
      unchangedPoints.push(...unchangedAddition);
      polygonPoints = unchangedPoints;
      polygonPoints.forEach(point => {
        // this.GraphicsThree3D.wireframePoints.push(point.clone());
        point.sub(midVector);
        point.applyEuler(eulerOne);
      });
      let shapePts = polygonPoints.map(point => new this.THREE.Vector2(point.x, point.y));
      let shape = new this.THREE.Shape(shapePts);
      extrusionVector.applyEuler(eulerOne);
      extrusionNormalized.applyEuler(eulerOne);
      let extrudeSettings = {
        depth: extrusionVector.z,
        bevelEnabled: false
      };
      let geometry = new this.THREE.ExtrudeGeometry(shape, extrudeSettings);
      let polygon = new this.THREE.Mesh(geometry);
      polygon.position.add(midVector);
      polygon.updateMatrix();
      polygon.setRotationFromEuler(eulerTwo);
      return polygon;
    }

    drawPolygonRotated(polygonData, propertiesData) {
      let rotationPoint = this.utils.initVector3(polygonData.objectGeneration.rotationPoint);
      let rotationAxis = this.utils.initVector3(polygonData.objectGeneration.rotationAxis).normalize();
      let rotationAngle = polygonData.objectGeneration.rotationAngle * Math.PI / 180;
      let rotationPitch = polygonData.objectGeneration.rotationPitch / 2;
      let pts = this.getPolygonPlanarPoints(polygonData); // let rotatedGeometry = new RotatedGeometry(
      //   pts.map( pt => pt.clone() ),
      //   {
      //     rotationPoint: rotationPoint.clone(),
      //     rotationAxis: rotationAxis.clone(),
      //     rotationAngle,
      //     rotationPitch: polygonData.objectGeneration.rotationPitch
      //   }
      // );
      // let redMesh = new this.THREE.Mesh(
      //   rotatedGeometry,
      //   new this.THREE.MeshBasicMaterial({color: 0xff0000, side: this.THREE.DoubleSide})
      // );
      // this.GraphicsThree3D.scene.add(redMesh);

      let centerPoint = this.GraphicsThree3D.getPointsCenter3D(pts);
      pts.forEach(point => point.sub(centerPoint));
      let centProjection = centerPoint.clone().projectOnPlane(rotationAxis);
      let rotProjection = rotationPoint.clone().projectOnPlane(rotationAxis);
      let rotationRadius = 0.01 + centProjection.distanceTo(rotProjection);
      let clockWise = true;

      if (rotationAngle > 0) {
        clockWise = false;
        rotationPitch *= -1;
      }

      let rotationCurve = new _CustomEllipseCurve.CustomEllipseCurve(0, 0, rotationRadius, rotationRadius, 0, rotationAngle, clockWise, 0, rotationPitch);
      let detail = Math.abs(32 * rotationAngle / Math.PI / 2);
      let polygonShape = new this.THREE.Shape(pts);
      let curveGeo = new this.THREE.ExtrudeGeometry(polygonShape, {
        steps: detail,
        extrudePath: rotationCurve
      });
      let polygon = new this.THREE.Mesh(curveGeo);
      let quaternion = new this.THREE.Quaternion().setFromUnitVectors(rotationAxis, polygon.up);
      polygon.quaternion.copy(quaternion);
      polygon.position.add(rotationPoint).add(new this.THREE.Vector3(0, rotationPitch, 0));
      polygon.updateMatrix(); ///
      // let rotationData = {positionPoint: rotationRadius, rotationPitch, rotationAngle, rotationAxis};
      // let objectWireframes = [];
      // this.GraphicsThree3D.makeRotatedObjectWireframe(pts, propertiesData.line, rotationData, objectWireframes, true);
      // objectWireframes.forEach(wireframe => {
      //   wireframe.position.y -= rotationPitch;
      //   wireframe.applyMatrix4(polygon.matrix);
      // });
      // this.GraphicsThree3D.rotationObjectWireframes.push(...objectWireframes);
      // curveGeo.mergeVertices();
      // let linePairs = this.GraphicsThree3D.getWireframeLinePairs(curveGeo);
      // linePairs.forEach(([ direction, pairs ]) => pairs.forEach( pair => {
      //   let wireframe = this.GraphicsThree3D.drawConnectedLines( pair, propertiesData.line );
      //   wireframe.renderOrder = -99;
      //   wireframe.applyMatrix4(polygon.matrix);
      //   this.GraphicsThree3D.rotationObjectWireframes.push( wireframe );
      // }));
      // let objectWireframes = [];
      // let wireframePts = this.getPolygonPlanarPoints(polygonData).map( pt => pt.sub( rotationPoint ) );
      // objectWireframes.push( this.GraphicsThree3D.drawConnectedLines( wireframePts, propertiesData.line, true ) );
      // let edgeDetail = 32;
      // let numOfSegments = edgeDetail * Math.abs(rotationAngle / Math.PI / 2);
      // wireframePts.forEach(point => {
      //   let localPoint = point.clone();
      //   let edgePoints = [];
      //   for (let i = 0; i < numOfSegments + 1; i++) {
      //     let newPoint = localPoint.clone().applyAxisAngle(rotationAxis, i * rotationAngle / numOfSegments);
      //     newPoint.sub(new this.THREE.Vector3(0, i * rotationPitch / numOfSegments, 0));
      //     edgePoints.push(newPoint);
      //   }
      //   let edgeLine = this.GraphicsThree3D.drawConnectedLines(edgePoints, propertiesData.line);
      //   objectWireframes.push(edgeLine);
      // });
      // let endPoints = wireframePts.map(pt => pt.clone().applyAxisAngle( rotationAxis, rotationAngle ));
      // objectWireframes.push( this.GraphicsThree3D.drawConnectedLines( endPoints, propertiesData.line, true ) );
      // objectWireframes.map( wireframe => wireframe.position.add( rotationPoint ) );
      // this.GraphicsThree3D.rotationObjectWireframes.push( ...objectWireframes );
      ///
      ///

      return polygon;
    }

    drawPolygonRotatedPlanar(polygonData) {
      let POINT_DISTANCE = 0.3;
      let polygonDepth = 0.01;
      let rotationPoint = polygonData.objectGeneration.rotationPoint;
      let rotationAxis = this.utils.initVector3(polygonData.objectGeneration.rotationAxis).normalize();
      let rotationAngle = -polygonData.objectGeneration.rotationAngle * Math.PI / 180;
      let rotationPitch = polygonData.objectGeneration.rotationPitch;
      let tempPoints = this.getPolygonPlanarPoints(polygonData);
      let extrusionVector = new this.THREE.Vector3(0, 0, polygonDepth);
      let extrusionNormalized = extrusionVector.clone().normalize();
      let [midVector, eulerOne, eulerTwo] = this.GraphicsThree3D.getRotationParameters(tempPoints, extrusionNormalized);
      let polygonPoints = [];

      for (let i = 0; i < tempPoints.length - 1; i++) {
        let newPoints = this.GraphicsThree3D.interpolateWithDistance(tempPoints[i], tempPoints[i + 1], POINT_DISTANCE);
        polygonPoints.push(...newPoints);
      }

      let newPoints = this.GraphicsThree3D.interpolateWithDistance(tempPoints[tempPoints.length - 1], tempPoints[0], POINT_DISTANCE);
      polygonPoints.push(...newPoints); ///

      let tempCross = new this.THREE.Vector3(0, 0, 0);

      for (let i = 0; i < tempPoints.length - 2; i++) {
        let ptZero = tempPoints[i];
        let ptOne = tempPoints[i + 1];
        let ptTwo = tempPoints[i + 2];
        let vecOne = new this.THREE.Vector3().subVectors(ptOne, ptZero);
        let vecTwo = new this.THREE.Vector3().subVectors(ptTwo, ptOne);
        let cross = new this.THREE.Vector3().crossVectors(vecOne, vecTwo).normalize();
        tempCross.add(cross);
      }

      tempCross.normalize();
      if (tempCross.dot(extrusionNormalized) > 0) polygonPoints.reverse();
      let xAxis = new this.THREE.Vector3(1, 0, 0);
      let rotationIndex = 0;
      let minAngle = Infinity;
      polygonPoints.forEach((point, index) => {
        let localPoint = point.clone().sub(midVector).normalize();
        let angle = Math.abs(localPoint.dot(xAxis));

        if (angle < minAngle) {
          rotationIndex = index;
          minAngle = angle;
        }
      });
      this.GraphicsThree3D.cycleArray(polygonPoints, rotationIndex); // rotation code

      let axeVector = new this.THREE.Vector3(0, 0, 1);
      let rotationVector = this.utils.initVector3(rotationPoint).sub(midVector);
      let numOfSegments = Math.abs(64 * rotationAngle / Math.PI / 2);

      if (rotationAngle > 0) {
        polygonPoints.push(polygonPoints[0].clone());
        let [startIndex, endIndex] = this.GraphicsThree3D.getRotationIndices(polygonPoints, rotationVector);
        this.GraphicsThree3D.cycleArray(polygonPoints, endIndex);
        let pivotOne = polygonPoints[0].clone();
        let pivotTwo = polygonPoints[Math.floor(polygonPoints.length / 2)].clone();
        let polygonStartPoints = polygonPoints.splice(Math.floor(polygonPoints.length / 2)).map(point => point.applyAxisAngle(axeVector, rotationAngle));
        let segmentRotation = rotationAngle / numOfSegments;

        for (let i = 0; i < numOfSegments; i++) {
          pivotOne = pivotOne.clone();
          pivotTwo = pivotTwo.clone();
          polygonPoints.unshift(pivotOne.applyAxisAngle(axeVector, segmentRotation));
          polygonPoints.push(pivotTwo.applyAxisAngle(axeVector, segmentRotation));
        }

        polygonPoints.unshift(...polygonStartPoints);
      } else {
        polygonPoints.push(polygonPoints[0].clone());
        let [startIndex, endIndex] = this.GraphicsThree3D.getRotationIndices(polygonPoints, rotationVector);
        this.GraphicsThree3D.cycleArray(polygonPoints, startIndex);
        let pivotOne = polygonPoints[0].clone();
        let pivotTwo = polygonPoints[Math.floor(polygonPoints.length / 2)].clone();
        let polygonStartPoints = polygonPoints.splice(Math.floor(polygonPoints.length / 2)).map(point => point.applyAxisAngle(axeVector, rotationAngle));
        let segmentRotation = rotationAngle / numOfSegments;

        for (let i = 0; i < numOfSegments; i++) {
          pivotOne = pivotOne.clone();
          pivotTwo = pivotTwo.clone();
          polygonPoints.unshift(pivotOne.applyAxisAngle(axeVector, segmentRotation));
          polygonPoints.push(pivotTwo.applyAxisAngle(axeVector, segmentRotation));
        }

        polygonPoints.unshift(...polygonStartPoints);
      } ///


      polygonPoints.forEach(point => {
        // this.GraphicsThree3D.wireframePoints.push(point.clone());
        point.sub(midVector);
        point.applyEuler(eulerOne);
      });
      let shapePts = polygonPoints.map(point => new this.THREE.Vector2(point.x, point.y));
      let shape = new this.THREE.Shape(shapePts);
      extrusionVector.applyEuler(eulerOne);
      extrusionNormalized.applyEuler(eulerOne);
      let extrudeSettings = {
        depth: extrusionVector.z,
        bevelEnabled: false
      };
      let geometry = new this.THREE.ExtrudeGeometry(shape, extrudeSettings);
      let polygon = new this.THREE.Mesh(geometry);
      polygon.position.add(midVector);
      polygon.updateMatrix();
      polygon.setRotationFromEuler(eulerTwo);
      return polygon;
    }

    getPolygonPlanarPoints(polygonData) {
      let points = [];

      for (let c = 0; c < polygonData.points.length; c++) {
        let x = polygonData.points[c].x;
        let y = polygonData.points[c].y;
        let z = polygonData.points[c].z;
        points.push(new this.THREE.Vector3(x, y, z));
      }

      return points;
    }

    polygonIsPlanar(polygonData) {
      return polygonData.objectGeneration.type === 1 || polygonData.objectGeneration.type === 2 && this.utils.vectorIsZero(polygonData.objectGeneration.extrusionVector) || polygonData.objectGeneration.type === 2 && this.GraphicsThree3D.objectExtrudesPlanar(polygonData) || polygonData.objectGeneration.type === 3 && this.GraphicsThree3D.objectRotatesPlanar(polygonData);
    }

  }

  _exports.Polygon = Polygon;
});