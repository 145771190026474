define("m08-2020/lib/FigureLoaders/DrawingObjectLoader", ["exports", "m08-2020/lib/FigureLoaders/DrawingObjectComponents/Point", "m08-2020/lib/FigureLoaders/DrawingObjectComponents/Polyline", "m08-2020/lib/FigureLoaders/DrawingObjectComponents/Ellipse", "m08-2020/lib/FigureLoaders/DrawingObjectComponents/Polygon", "m08-2020/lib/FigureLoaders/DrawingObjectComponents/Ellipsoid", "m08-2020/lib/FigureLoaders/DrawingObjectComponents/ComplexVolume", "m08-2020/lib/Utils/BooleanOperations"], function (_exports, _Point, _Polyline, _Ellipse, _Polygon, _Ellipsoid, _ComplexVolume, BooleanOperations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DrawingObjectLoader = void 0;

  class DrawingObjectLoader {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.THREE = GraphicsThree3D.THREE;
      this.utils = GraphicsThree3D.utils;
      this.WireframeGenerator = GraphicsThree3D.WireframeGenerator;
      this.Ellipsoid = new _Ellipsoid.Ellipsoid(GraphicsThree3D);
      this.Point = new _Point.Point(GraphicsThree3D);
      this.Polyline = new _Polyline.Polyline(GraphicsThree3D);
      this.Ellipse = new _Ellipse.Ellipse(GraphicsThree3D);
      this.Polygon = new _Polygon.Polygon(GraphicsThree3D);
      this.ComplexVolume = new _ComplexVolume.ComplexVolume(GraphicsThree3D);
    }

    drawPoints(points, properties, positivesAndNegatives) {
      points.forEach(point => {
        if (point.objectType > 0 && point.objectType < 3) {
          let pointMesh = this.Point.drawPoint(point, properties);
          if (point.objectGeneration.type === 1) this.addToPosAndNeg(pointMesh, point, positivesAndNegatives, "point");else if (point.objectGeneration.type === 2 || point.objectGeneration.type === 3) this.addToPosAndNeg(pointMesh, point, positivesAndNegatives, "line");
        }
      });
    }

    drawPolylines(polylines, properties, positivesAndNegatives) {
      polylines.forEach(polyline => {
        if (polyline.objectType > 0 && polyline.objectType < 3) {
          let lineMesh = this.Polyline.drawPolyline(polyline, properties);
          if (polyline.objectGeneration.type === 1) this.addToPosAndNeg(lineMesh, polyline, positivesAndNegatives, "line");else if (polyline.objectGeneration.type === 2 || polyline.objectGeneration.type === 3) this.addToPosAndNeg(lineMesh, polyline, positivesAndNegatives, "area");
        }
      });
    }

    drawEllipses(ellipses, properties, positivesAndNegatives) {
      ellipses.forEach(ellipse => {
        console.log(ellipse);

        if (ellipse.objectType > 0 && ellipse.objectType < 3) {
          let ellipseMesh = this.Ellipse.drawEllipse(ellipse, properties);
          ellipseMesh.updateMatrix();

          if (ellipse.objectGeneration.type === 1 || ellipse.objectGeneration.type === 2 && this.GraphicsThree3D.objectExtrudesPlanar(ellipse, true) || ellipse.objectGeneration.type === 3 && this.GraphicsThree3D.objectRotatesPlanar(ellipse, true)) {
            this.addToPosAndNeg(ellipseMesh, ellipse, positivesAndNegatives, "area");
          } else {
            if (ellipse.objectGeneration.type === 2) this.addToPosAndNeg(ellipseMesh, ellipse, positivesAndNegatives, "volumeRegular");else if (ellipse.objectGeneration.type === 3) this.addToPosAndNeg(ellipseMesh, ellipse, positivesAndNegatives, "volumeRounded");
          }
        }
      });
    }

    drawPolygons(polygons, properties, positivesAndNegatives) {
      polygons.forEach(polygon => {
        if (polygon.objectType > 0 && polygon.objectType < 3 && polygon.objectGeneration.type > 0 && polygon.objectGeneration.type < 4) {
          let polygonMesh = this.Polygon.drawPolygon(polygon, properties);
          if (this.Polygon.polygonIsPlanar(polygon)) this.addToPosAndNeg(polygonMesh, polygon, positivesAndNegatives, "area");else {
            if (polygon.objectGeneration.type === 2) this.addToPosAndNeg(polygonMesh, polygon, positivesAndNegatives, "volumeRegular");else if (polygon.objectGeneration.type === 3) this.addToPosAndNeg(polygonMesh, polygon, positivesAndNegatives, "volumeRegular");
          }
        }
      });
    }

    drawEllipsoids(ellipsoids, positivesAndNegatives) {
      ellipsoids.forEach(ellipsoid => {
        if (ellipsoid.objectType > 0 && ellipsoid.objectType < 3) {
          let ellipsoidMesh = this.Ellipsoid.drawEllipsoid(ellipsoid);
          this.addToPosAndNeg(ellipsoidMesh, ellipsoid, positivesAndNegatives, "volumeRounded");
        }
      });
    }

    drawComplexVolumes(drawingObject, jsonData) {
      let components = drawingObject.components;
      let properties = drawingObject.properties;
      let multilayeredObjects = {
        positives: [],
        negatives: []
      };
      let wireframes = [];
      if (components.polygons) wireframes.push(...this.ComplexVolume.drawMultilayeredPolygons(components.polygons, properties, multilayeredObjects));
      if (components.ellipses) wireframes.push(...this.ComplexVolume.drawMultilayeredEllipses(components.ellipses, properties, multilayeredObjects));
      if (components.ellipsoids) wireframes.push(...this.ComplexVolume.drawMultilayeredEllipsoids(components.ellipsoids, properties, multilayeredObjects));
      this.ComplexVolume.addMultilayeredObjectsToScene(multilayeredObjects, wireframes, properties, drawingObject.insertionPoints, jsonData, drawingObject);
    }

    drawRegularObjects(drawingObject, jsonData) {
      if (drawingObject.insertionPoints) {
        let components = drawingObject.components;
        let properties = drawingObject.properties;
        let positivesAndNegatives = {
          area: {
            positiveObjects: [],
            negativeObjects: []
          },
          volumeRegular: {
            positiveObjects: [],
            negativeObjects: []
          },
          volumeRounded: {
            positiveObjects: [],
            negativeObjects: []
          },
          point: {
            positiveObjects: [],
            negativeObjects: []
          },
          line: {
            positiveObjects: [],
            negativeObjects: []
          }
        };
        if (components.points) this.drawPoints(components.points, properties, positivesAndNegatives);
        if (components.polylines) this.drawPolylines(components.polylines, properties, positivesAndNegatives);
        if (components.ellipses) this.drawEllipses(components.ellipses, properties, positivesAndNegatives);
        if (components.polygons) this.drawPolygons(components.polygons, properties, positivesAndNegatives);
        if (components.ellipsoids) this.drawEllipsoids(components.ellipsoids, positivesAndNegatives);
        let areas = positivesAndNegatives.area;
        let volumesRegular = positivesAndNegatives.volumeRegular;
        let volumesRounded = positivesAndNegatives.volumeRounded;
        let points = positivesAndNegatives.point;
        let lines = positivesAndNegatives.line;
        this.generateAreas(areas, drawingObject, properties, jsonData); ///

        this.generateVolumes(volumesRegular, volumesRounded, drawingObject, properties, jsonData); ///

        this.generatePoints(points, drawingObject, properties, jsonData);
        this.generateLines(lines, drawingObject, properties, jsonData);
      }
    }

    generateAreas(areas, drawingObject, properties, jsonData) {
      let areaPos = areas.positiveObjects;
      let areaNeg = areas.negativeObjects;

      if (areaPos.length > 0) {
        let positiveObject = BooleanOperations.applyCSG(areaPos, areaNeg);
        let objectMaterial = this.GraphicsThree3D.getAreaMaterial(positiveObject, properties);

        if (objectMaterial !== null) {
          positiveObject.material = objectMaterial;
          positiveObject.material.side = this.THREE.FrontSide;
          let object = positiveObject;
          let objectTransparent = this.GraphicsThree3D.makeTransparentClone(object);
          objectTransparent.applyMatrix4(object.matrix);
          this.addToDimChainCollisionDetection(positiveObject, drawingObject.insertionPoints);
          object.stencilNeeded = true;
          object.addToDXF = true;
          this.WireframeGenerator.applyWireframesArea(object, objectTransparent, properties.line, drawingObject);
          this.GraphicsThree3D.addObjectToScene(object, objectTransparent, drawingObject.insertionPoints, jsonData, "area", "drawingObject", drawingObject);
        }
      }
    }

    generateVolumes(volumesRegular, volumesRounded, drawingObject, properties, jsonData) {
      let volumePosRegular = volumesRegular.positiveObjects;
      let volumeNegRegular = volumesRegular.negativeObjects;
      let volumePosRounded = volumesRounded.positiveObjects;
      let volumeNegRounded = volumesRounded.negativeObjects;
      let positiveObjectRegular = volumePosRegular.length > 0 ? BooleanOperations.applyCSG(volumePosRegular, volumeNegRegular) : null;
      let positiveObjectRounded = volumePosRounded.length > 0 ? BooleanOperations.applyCSG(volumePosRounded, volumeNegRounded) : null;
      let positiveObject;
      if (positiveObjectRegular && positiveObjectRounded) positiveObject = BooleanOperations.doCSG(positiveObjectRegular, positiveObjectRounded, "union");else if (positiveObjectRegular && !positiveObjectRounded && volumeNegRounded.length > 0) {
        positiveObject = positiveObjectRegular;
        volumeNegRounded.forEach(negativeObject => positiveObject = BooleanOperations.doCSG(positiveObject, negativeObject, "subtract"));
      } else if (positiveObjectRegular && !positiveObjectRounded) positiveObject = positiveObjectRegular;else if (!positiveObjectRegular && positiveObjectRounded && volumeNegRegular.length > 0) {
        positiveObject = positiveObjectRounded;
        volumeNegRegular.forEach(negativeObject => positiveObject = BooleanOperations.doCSG(positiveObject, negativeObject, "subtract"));
      } else if (!positiveObjectRegular && positiveObjectRounded) positiveObject = positiveObjectRounded;else positiveObject = null;

      if (positiveObject) {
        ///
        volumeNegRegular.forEach(negativeObject => positiveObject = BooleanOperations.doCSG(positiveObject, negativeObject, "subtract"));
        volumeNegRounded.forEach(negativeObject => positiveObject = BooleanOperations.doCSG(positiveObject, negativeObject, "subtract")); ///

        let objectMaterial = this.GraphicsThree3D.getVolumeMaterialsIndexFaces(positiveObject, properties); // objectMaterial.forEach( mat => mat.wireframe = true );

        if (objectMaterial !== null) {
          objectMaterial.forEach(mat => mat.side = this.THREE.FrontSide);
          positiveObject.material = objectMaterial;
          let [doubleSideObject, frontSideObject, backSideObject] = this.GraphicsThree3D.getThreeSideFaceObjects(positiveObject);
          this.addToDimChainCollisionDetection(positiveObject, drawingObject.insertionPoints);
          this.GraphicsThree3D.indexMeshFaces(frontSideObject, properties);
          this.GraphicsThree3D.indexMeshFaces(backSideObject, properties);
          let objectTransparent = this.GraphicsThree3D.makeTransparentClone(positiveObject);
          let geo = positiveObject.geometry.clone();
          backSideObject.renderOrder = 0; // DepthClear mesh

          let depthClearMesh = new this.THREE.Mesh(geo, objectMaterial);
          depthClearMesh.applyMatrix4(positiveObject.matrix);
          depthClearMesh.renderOrder = -100;

          depthClearMesh.onBeforeRender = function (renderer) {
            renderer.clearDepth();
          };

          this.GraphicsThree3D.addObjectToScene(depthClearMesh, objectTransparent, drawingObject.insertionPoints, jsonData, "volume", "drawingObject", drawingObject); // FrontSide material mesh

          frontSideObject.stencilNeeded = true;
          frontSideObject.addToDXF = true;
          frontSideObject.dxfLayer = `drawingObject_${drawingObject.id}`; ///

          if (positiveObjectRegular) {
            let linePairs = this.WireframeGenerator.getRegularWireframePointPairs(positiveObjectRegular);
            linePairs = linePairs.map(pair => [pair[0].clone().applyMatrix4(positiveObjectRegular.matrix), pair[1].clone().applyMatrix4(positiveObjectRegular.matrix)]);
            this.WireframeGenerator.getVolumeWireframeData(positiveObjectRegular, linePairs, linePairs, 1);
          }

          this.WireframeGenerator.applyWireframesVolume(frontSideObject, objectTransparent, properties.line, drawingObject); ///

          this.GraphicsThree3D.addObjectToScene(frontSideObject, objectTransparent, drawingObject.insertionPoints, jsonData, "volume", "drawingObject", drawingObject); // BackSide material mesh

          this.GraphicsThree3D.addObjectToScene(backSideObject, objectTransparent, drawingObject.insertionPoints, jsonData, "volume", "drawingObject", drawingObject);
        }
      }
    }

    generatePoints(points, drawingObject, properties, jsonData) {
      let pointPos = points.positiveObjects;
      let pointNeg = points.negativeObjects;

      if (pointPos.length > 0) {
        let positiveObject = BooleanOperations.applyCSG(pointPos, pointNeg);
        let objectMaterial = this.GraphicsThree3D.getPointMaterial(properties);

        if (objectMaterial !== null) {
          positiveObject.material = objectMaterial;
          let object = positiveObject;
          let objectTransparent = null;
          this.addToDimChainCollisionDetection(positiveObject, drawingObject.insertionPoints);
          this.GraphicsThree3D.addObjectToScene(object, objectTransparent, drawingObject.insertionPoints, jsonData, "point", "drawingObject", drawingObject);
          this.addPointObjectsToDXF(drawingObject);
        }
      }
    }

    generateLines(lines, drawingObject, properties, jsonData) {
      // TODO: AddLines back to dim chain collision detection
      let linePos = lines.positiveObjects;
      let lineNeg = lines.negativeObjects;

      if (linePos.length > 0) {
        linePos.forEach(line => {
          let objectMaterial = this.GraphicsThree3D.getlineMeshMaterial(properties);

          if (objectMaterial !== null) {
            line.material = objectMaterial;
            let object = line;
            object.renderOrder = -6;
            let objectTransparent = null; // this.addToDimChainCollisionDetection(line, drawingObject.insertionPoints);

            this.GraphicsThree3D.addObjectToScene(object, objectTransparent, drawingObject.insertionPoints, jsonData, "line", "drawingObject", drawingObject);
            this.addLineObjectsToDXF(drawingObject);
          }
        }); // let positiveObject = BooleanOperations.applyCSG(linePos, lineNeg);
        // let objectMaterial = this.GraphicsThree3D.getlineMeshMaterial(properties);
        // if (objectMaterial !== null) {
        //   positiveObject.material = objectMaterial;
        //   let object = positiveObject;
        //   object.renderOrder = -6;
        //   let objectTransparent = null;
        //   this.addToDimChainCollisionDetection(positiveObject, drawingObject.insertionPoints);
        //   this.GraphicsThree3D.addObjectToScene(object, objectTransparent, drawingObject.insertionPoints, jsonData, "line", "drawingObject", drawingObject);
        //   this.addLineObjectsToDXF(drawingObject);
        // }
      }
    }

    addLineObjectsToDXF(lineData) {
      let insertionPoints = lineData.insertionPoints;
      let points = lineData.components.points;
      let polylines = lineData.components.polylines;
      insertionPoints.forEach(insertionPoint => {
        let insertionVector = insertionPoint.insertionVector;
        if (points) points.forEach(point => {
          if (point.objectType === 1) {
            if (point.objectGeneration.type === 2) {
              let pointPair = this.Point.getExtrudedPointPoints(point.insertionPoint, point.objectGeneration.extrusionVector);
              if (insertionPoint.rotationAngle) pointPair = pointPair.map(point => this.utils.applyRotationToVector(point, insertionPoint));
              pointPair.forEach(point => point.add(insertionVector));
              this.GraphicsThree3D.dxfGenerator.lineObjectsData.push({
                id: lineData.id,
                properties: lineData.properties.line,
                pointPairs: [pointPair]
              });
            } else if (point.objectGeneration.type === 3) {
              let circlePoints = this.Point.getRotatedPoints(point);
              if (insertionPoint.rotationAngle) circlePoints = circlePoints.map(point => this.utils.applyRotationToVector(point, insertionPoint));
              circlePoints.forEach(point => point.add(insertionVector));
              let circlePointPairs = [];

              for (let i = 0; i < circlePoints.length - 1; i++) circlePointPairs.push([circlePoints[i], circlePoints[i + 1]]);

              this.GraphicsThree3D.dxfGenerator.lineObjectsData.push({
                id: lineData.id,
                properties: lineData.properties.line,
                pointPairs: circlePointPairs
              });
            }
          }
        });
        if (polylines) polylines.forEach(polyline => {
          if (polyline.objectType === 1 && polyline.objectGeneration.type === 1) {
            let polylinePoints = polyline.points.map(point => this.utils.initVector3(point));
            if (insertionPoint.rotationAngle) polylinePoints = polylinePoints.map(point => this.utils.applyRotationToVector(point, insertionPoint));
            polylinePoints.forEach(point => point.add(insertionVector));
            let pointPairs = [];

            for (let i = 0; i < polylinePoints.length - 1; i++) pointPairs.push([polylinePoints[i], polylinePoints[i + 1]]);

            this.GraphicsThree3D.dxfGenerator.lineObjectsData.push({
              id: lineData.id,
              properties: lineData.properties.line,
              pointPairs
            });
          }
        });
      });
    }

    addPointObjectsToDXF(pointData) {
      let insertionPoints = pointData.insertionPoints;
      let points = pointData.components.points;
      points.forEach(point => {
        if (point.objectType === 1 && point.objectGeneration.type === 1) {
          insertionPoints.forEach(insertionPoint => {
            let insertionVector = insertionPoint.rotationAngle ? this.utils.applyRotationToVector(insertionPoint.insertionVector, insertionPoint) : insertionPoint.insertionVector;
            this.GraphicsThree3D.dxfGenerator.pointObjectsData.push({
              id: pointData.id,
              properties: pointData.properties.point,
              position: this.utils.addVectorObjects(point.insertionPoint, insertionVector)
            });
          });
        }
      });
    }

    addToDimChainCollisionDetection(object, insertionPoints) {
      insertionPoints.forEach(insertionPoint => {
        let insertionVector = this.utils.initVector3(insertionPoint.insertionVector);
        let newObject = object.clone();
        let rotationAngle = insertionPoint.rotationAngle;
        let pivotPoint = new this.THREE.Object3D();

        if (rotationAngle) {
          rotationAngle = rotationAngle * Math.PI / 180;
          let rotationAxis = this.utils.initVector3(insertionPoint.rotationAxis);
          pivotPoint.position.set(insertionPoint.rotationPoint.x, insertionPoint.rotationPoint.y, insertionPoint.rotationPoint.z);
          pivotPoint.attach(newObject);
          pivotPoint.rotateOnAxis(rotationAxis, rotationAngle);
        } else pivotPoint.attach(newObject);

        pivotPoint.position.add(insertionVector);
        pivotPoint.updateMatrixWorld();
        let baseObject = pivotPoint.children[0];
        pivotPoint.children.forEach(child => child.applyMatrix4(pivotPoint.matrixWorld));
        let intersectionObjects = this.GraphicsThree3D.getThreeSideFaceObjects(baseObject);
        this.GraphicsThree3D.DimensionalChainIntersectionDetection.meshArr.push(intersectionObjects);
      });
    }

    addToPosAndNeg(mesh, objectData, positivesAndNegatives, type) {
      if (objectData.objectType == 1) positivesAndNegatives[type].positiveObjects.push(mesh);else positivesAndNegatives[type].negativeObjects.push(mesh);
    }

    addPointsToWireframeRegions(points, wireframeRegions) {
      points.forEach(pointsData => {
        let offsetVector = pointsData.normal.clone().multiplyScalar(pointsData.distance);
        let pointsArr = pointsData.pointsArrData;
        let [eulerOne, eulerTwo] = this.utils.getEulerOneAndTwo(pointsData.normal);
        let pointsPos = pointsArr.positives;
        let pointsNeg = pointsArr.negatives;
        let newRegionData = {
          offsetVector,
          eulerTwo,
          positiveRegions: [],
          negativeRegions: []
        };
        pointsPos.forEach(pointArr => {
          let newRegion = {
            regions: [[]],
            inverted: false
          };
          pointArr.forEach(point => {
            point.applyEuler(eulerOne);
            newRegion.regions[0].push([point.x, point.y]);
          });
          newRegionData.positiveRegions.push(newRegion);
        });
        pointsNeg.forEach(pointArr => {
          let newRegion = {
            regions: [[]],
            inverted: false
          };
          pointArr.forEach(point => {
            point.applyEuler(eulerOne);
            newRegion.regions[0].push([point.x, point.y]);
          });
          newRegionData.negativeRegions.push(newRegion);
        });
        wireframeRegions.push(newRegionData);
      });
    }

  }

  _exports.DrawingObjectLoader = DrawingObjectLoader;
});