define("m08-2020/lib/FigureLoaders/DrawingObjectComponents/Ellipse", ["exports", "m08-2020/lib/ThreeExtensions/CustomEllipseCurve", "m08-2020/lib/FigureLoaders/DrawingObjectComponents/Ellipsoid", "m08-2020/lib/Utils/PointInsidePolygonCheck2D", "polybooljs"], function (_exports, _CustomEllipseCurve, _Ellipsoid, _PointInsidePolygonCheck2D, _polybooljs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Ellipse = void 0;

  class Ellipse {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.THREE = GraphicsThree3D.THREE;
      this.utils = GraphicsThree3D.utils;
      this.CSG = GraphicsThree3D.CSG;
      this.WireframeGenerator = GraphicsThree3D.WireframeGenerator;
      this.Ellipsoid = new _Ellipsoid.Ellipsoid(GraphicsThree3D);
    }

    drawEllipse(ellipseData, materialProps) {
      let lineProps = materialProps.line;
      let ellipseType = ellipseData.objectGeneration.type;
      let ellipseMesh;

      if (ellipseType === 1) {
        ellipseMesh = this.drawEllipsePlanar(ellipseData);
        ellipseMesh.generationType = "area";
      } else if (ellipseType === 2) {
        if (this.GraphicsThree3D.objectExtrudesPlanar(ellipseData, true)) {
          ellipseMesh = this.makeEllipseExtrudedArea(ellipseData);
          ellipseMesh.generationType = "area";
        } else {
          ellipseMesh = this.makeEllipseExtrudedVolume(ellipseData);
          ellipseMesh.generationType = "volume";
        }
      } else if (ellipseType === 3) {
        if (this.GraphicsThree3D.objectRotatesPlanar(ellipseData, true)) {
          ellipseMesh = this.makeEllipseRotatedArea(ellipseData);
          ellipseMesh.generationType = "area";
        } else {
          ellipseMesh = this.makeEllipseRotatedVolume(ellipseData, lineProps);
          ellipseMesh.generationType = "volume";
        }
      }

      return ellipseMesh;
    }

    drawEllipsePlanar(ellipseData) {
      let centerPoint = this.utils.initVector3(ellipseData.centerPoint);
      let extrusionDepth = 0.1;
      let extrusionVector = new this.THREE.Vector3(0, 0, extrusionDepth);
      let extrusionNormalized = extrusionVector.clone().normalize();
      let pts = this.GraphicsThree3D.getEllipsePlanarPoints(ellipseData);
      let [midVector, eulerOne, eulerTwo] = this.GraphicsThree3D.getRotationParameters(pts, extrusionNormalized);
      pts.forEach(point => {
        // this.GraphicsThree3D.wireframePoints.push(point.clone().add(centerPoint));
        point.sub(midVector);
        point.applyEuler(eulerOne);
      });
      let shapePts = pts.map(point => new this.THREE.Vector2(point.x, point.y));
      let ellipseShape = new this.THREE.Shape(shapePts);
      extrusionVector.applyEuler(eulerOne);
      extrusionNormalized.applyEuler(eulerOne);
      let extrudeSettings = {
        depth: extrusionDepth,
        bevelEnabled: false
      };
      let ellipseGeo = new this.THREE.ExtrudeGeometry(ellipseShape, extrudeSettings); // ellipseGeo.translate(0, 0, -extrusionVector.z);

      let ellipse = new this.THREE.Mesh(ellipseGeo);
      ellipse.position.add(midVector);
      ellipse.updateMatrix();
      ellipse.setRotationFromEuler(eulerTwo);
      ellipse.position.add(centerPoint);
      return ellipse;
    }

    makeEllipseExtrudedArea(ellipseData) {
      let centerPoint = this.utils.initVector3(ellipseData.centerPoint);
      let extrusionVector = new this.THREE.Vector3(ellipseData.objectGeneration.extrusionVector.x, ellipseData.objectGeneration.extrusionVector.y, 0);
      let curvePoints = this.GraphicsThree3D.getEllipsePlanarPoints(ellipseData); // TODO: Refactor this to be more readable end efficient

      if (ellipseData.segmentVector1) {
        if (!ellipseData.segmentHeight) {
          curvePoints.splice(2, 0, curvePoints[1].clone());
          curvePoints.splice(curvePoints.length - 1, 0, curvePoints[curvePoints.length - 1].clone());

          for (let i = 2; i < curvePoints.length - 1; i++) {
            curvePoints[i].add(extrusionVector);
          }
        } else {
          let extrusionDir = extrusionVector.clone().normalize();
          let midDir = curvePoints[Math.floor(curvePoints.length / 2)].clone().normalize(); ///

          if (midDir.dot(extrusionDir) < 0) {
            curvePoints.splice(2, 0, curvePoints[1].clone());
            curvePoints.splice(curvePoints.length - 1, 0, curvePoints[curvePoints.length - 2].clone());

            for (let i = 0; i < 2; i++) {
              curvePoints[i].add(extrusionVector);
            }

            for (let i = curvePoints.length - 2; i < curvePoints.length; i++) {
              curvePoints[i].add(extrusionVector);
            }
          } else {
            curvePoints.splice(2, 0, curvePoints[1].clone());
            curvePoints.splice(curvePoints.length - 1, 0, curvePoints[curvePoints.length - 2].clone());

            for (let i = 2; i < curvePoints.length - 2; i++) {
              curvePoints[i].add(extrusionVector);
            }
          }
        }
      } else {
        curvePoints.push(curvePoints[0].clone());
        let pointsNum = curvePoints.length;
        let startIndex = (Math.PI / 2 + extrusionVector.angleTo(new this.THREE.Vector3(1, 0, 0))) / (2 * Math.PI) * pointsNum;
        let endIndex = (3 * Math.PI / 2 + extrusionVector.angleTo(new this.THREE.Vector3(1, 0, 0))) / (2 * Math.PI) * pointsNum;

        if (endIndex >= pointsNum) {
          endIndex -= pointsNum;
        }

        startIndex = Math.floor(startIndex);
        endIndex = Math.floor(endIndex);

        if (startIndex < endIndex) {
          for (let i = 0; i < startIndex; i++) {
            curvePoints[i].add(extrusionVector);
          }

          for (let i = endIndex; i < pointsNum; i++) {
            curvePoints[i].add(extrusionVector);
          }
        } else {
          for (let i = endIndex; i < startIndex; i++) {
            curvePoints[i].add(extrusionVector);
          }
        }
      } // this.WireframeGenerator.getPlanarPoints(curvePoints.map(point => point.clone().add(centerPoint)), ellipseData.objectType);
      // this.GraphicsThree3D.extrusionVector = new this.THREE.Vector3(0, 0, 0);


      let curveShape = new this.THREE.Shape(curvePoints);
      let curveGeo = new this.THREE.ExtrudeBufferGeometry(curveShape, {
        depth: -0.01,
        bevelEnabled: false
      });
      let ellipse = new this.THREE.Mesh(curveGeo);
      ellipse.position.add(centerPoint);
      return ellipse;
    }

    makeEllipseExtrudedVolume(ellipseData) {
      let centerPoint = this.utils.initVector3(ellipseData.centerPoint);
      let extrusionVector = ellipseData.objectGeneration.extrusionVector;
      let extrusionNormalized = this.utils.initVector3(extrusionVector).normalize();
      let skewX = extrusionNormalized.x / extrusionNormalized.z;
      let skewY = extrusionNormalized.y / extrusionNormalized.z;
      let curvePoints = this.GraphicsThree3D.getEllipsePlanarPoints(ellipseData); // this.WireframeGenerator.getPlanarPoints(curvePoints.map(point => point.clone().add(centerPoint)), ellipseData.objectType);

      let curveShape = new this.THREE.Shape(curvePoints);
      let curveGeo = new this.THREE.ExtrudeGeometry(curveShape, {
        depth: extrusionVector.z,
        bevelEnabled: false
      }); // curveGeo.translate(0, 0, extrusionVector.z);

      curveGeo.applyMatrix4(new this.THREE.Matrix4().set(1, 0, skewX, 0, 0, 1, skewY, 0, 0, 0, 1, 0, 0, 0, 0, 1));
      let ellipse = new this.THREE.Mesh(curveGeo);
      ellipse.position.copy(centerPoint);
      ellipse.updateMatrix();
      return ellipse;
    }

    makeEllipseRotatedArea(ellipseData) {
      let centerPoint = ellipseData.centerPoint; ///

      let rotationPoint = ellipseData.objectGeneration.rotationPoint;
      let rotationAngle = ellipseData.objectGeneration.rotationAngle * Math.PI / 180;
      let rotationPitch = ellipseData.objectGeneration.rotationPitch;
      if (ellipseData.objectGeneration.rotationAxis.z < 0) rotationAngle = -rotationAngle;
      let centerVector = this.utils.initVector3(centerPoint);
      let rotationVector = this.utils.initVector3(rotationPoint).sub(centerVector);
      let curvePoints = this.GraphicsThree3D.getEllipsePlanarPoints(ellipseData).map(point => point.sub(rotationVector));
      let numOfSegments = 16;
      let segmentRotation = rotationAngle / numOfSegments;
      let extrusionVector = new this.THREE.Vector3(0, 0, 1);
      let extrusionNormalized = extrusionVector.clone().normalize();
      let [midVector, eulerOne, eulerTwo] = this.GraphicsThree3D.getRotationParameters(curvePoints, extrusionNormalized);
      curvePoints.forEach(point => point.applyEuler(eulerOne));
      this.makePointReferences(curvePoints);
      let finalPoints = [...curvePoints];
      let previousPoints = [...curvePoints];

      for (let s = 1; s < 2; s++) {
        let newPoints = curvePoints.map(pt => pt.clone().applyAxisAngle(extrusionNormalized, segmentRotation * s));
        this.makePointReferences(newPoints);
        this.markPointsToDelete(previousPoints, newPoints);
        this.markPointsToDelete(newPoints, previousPoints);
        let generatedPoints = [];
        let startIndex = 0; // do {
        //   let newIndex = this.addWhileNotMarked(generatedPoints, newPoints, startIndex)
        //   console.log(newIndex);
        //   startIndex = newIndex;
        //   newIndex = 19;
        // } while ( startIndex < 19 );
        // finalPoints.push( ...generatedPoints );
      }

      curvePoints = finalPoints; ///
      // let startingRegion = {
      //   regions: [
      //     curvePoints.map(pt => { let point = pt.clone(); return [ point.x, point.y ] })
      //   ],
      //   inverted: false
      // };
      // let regionsToAdd = [];
      // for (let i = 0; i < numOfSegments; i++) {
      //   let endingRegion = {
      //     regions: [
      //       curvePoints.map(pt => { let point = pt.clone().applyAxisAngle(extrusionNormalized, segmentRotation * i); return [ point.x, point.y ]; })
      //     ],
      //     inverted: false
      //   };
      //   regionsToAdd.push(PolyBool.segments(endingRegion));
      // }
      // let segments = PolyBool.segments(startingRegion);
      // for (let i = 1; i < regionsToAdd.length; i++) {
      //   let comb = PolyBool.combine(segments, regionsToAdd[i]);
      //   segments = PolyBool.selectUnion(comb);
      // }
      // startingRegion = PolyBool.polygon(segments)
      // curvePoints = startingRegion.regions[0].map(pair => new this.THREE.Vector3( pair[0], pair[1], 0 ));
      ///

      let curveShape = new this.THREE.Shape(curvePoints);
      let curveGeo = new this.THREE.ExtrudeGeometry(curveShape, {
        depth: -0.01,
        bevelEnabled: false
      });
      let ellipse = new this.THREE.Mesh(curveGeo);
      ellipse.setRotationFromEuler(eulerTwo);
      ellipse.position.set(rotationPoint.x, rotationPoint.y, rotationPoint.z);
      return ellipse;
    }

    addWhileNotMarked(generatedPoints, pointArr, startIndex) {
      for (let i = startIndex; i < pointArr.length; i++) {
        if (!pointArr[i].markedToDelete) {
          generatedPoints.push(pointArr[i]);
        } else {
          return pointArr[i].nextPointIndex;
        }
      }
    }

    makePointReferences(pointArr) {
      let l = pointArr.length;

      for (let i = 0; i < l - 1; i++) pointArr[i].nextPointIndex = i + 1;

      pointArr[l - 1].nextPointIndex = 0;
    }

    markPointsToDelete(polygon, pointArr) {
      for (let i = 0; i < pointArr.length; i++) {
        if ((0, _PointInsidePolygonCheck2D.pointInsidePolygon2D)(polygon, pointArr[i])) {
          pointArr[i].markedToDelete = true;
        }
      }
    }

    makeEllipseRotatedVolume(ellipseData, lineProps) {
      let ellipse;

      if (this.rotationAxisDefined(ellipseData.objectGeneration.rotationAxis)) {
        let centerPoint = this.utils.initVector3(ellipseData.centerPoint);
        let rotationPoint = this.utils.initVector3(ellipseData.objectGeneration.rotationPoint);
        let rotationAxis = this.utils.initVector3(ellipseData.objectGeneration.rotationAxis).normalize();
        let rotationAngle = ellipseData.objectGeneration.rotationAngle * Math.PI / 180;
        let rotationPitch = ellipseData.objectGeneration.rotationPitch;
        let curvePoints = this.GraphicsThree3D.getEllipsePlanarPoints(ellipseData);
        let wireframePoints = curvePoints.map(point => point.clone());
        let zRotation = rotationAngle < 0 ? Math.PI / 2 : -Math.PI / 2;
        curvePoints.forEach(point => point.applyAxisAngle(new this.THREE.Vector3(0, 0, 1), zRotation));
        let centProjection = centerPoint.clone().projectOnPlane(rotationAxis);
        let rotProjection = rotationPoint.clone().projectOnPlane(rotationAxis);
        let rotationRadius = 0.01 + centProjection.distanceTo(rotProjection);

        if (this.generatesEllipsoid(rotationRadius, rotationAngle, rotationPitch)) {
          let ellipsoidData = this.generateEllipsoidData(ellipseData, rotationAxis);
          ellipse = this.Ellipsoid.drawEllipsoid(ellipsoidData);
        } else {
          let clockWise = true;

          if (rotationAngle > 0) {
            curvePoints.forEach(point => point.applyAxisAngle(new this.THREE.Vector3(0, 1, 0), Math.PI));
            clockWise = false;
            rotationPitch *= -1;
          }

          let rotationCurve = new _CustomEllipseCurve.CustomEllipseCurve(0, 0, rotationRadius, rotationRadius, 0, rotationAngle, clockWise, 0, rotationPitch);
          let detail = 64;
          let curveSteps = Math.floor(detail * (Math.abs(rotationAngle) / Math.PI / 2));
          let curveShape = new this.THREE.Shape(curvePoints);
          let curveGeo = new this.THREE.ExtrudeGeometry(curveShape, {
            bevelEnabled: false,
            steps: curveSteps,
            extrudePath: rotationCurve
          });
          ellipse = new this.THREE.Mesh(curveGeo); // let rotationData = {positionPoint: rotationRadius, rotationPitch, rotationAngle, rotationAxis};
          // this.GraphicsThree3D.makeRotatedObjectWireframe(wireframePoints, lineProps, rotationData, this.GraphicsThree3D.rotationObjectWireframes);

          if (ellipse.up.dot(rotationAxis) === -1) rotationAxis.applyAxisAngle(new this.THREE.Vector3(1, 0, 0), Math.PI / 100);
          let quaternion = new this.THREE.Quaternion().setFromUnitVectors(ellipse.up, rotationAxis);
          ellipse.quaternion.copy(quaternion);
          ellipse.position.set(rotationPoint.x, centerPoint.y, rotationPoint.z);
        }
      } else ellipse = new this.THREE.Mesh();

      return ellipse;
    }

    rotationAxisDefined(rotationAxis) {
      return rotationAxis && (rotationAxis.x || rotationAxis.y || rotationAxis.z);
    }

    generatesEllipsoid(rotationRadius, rotationAngle, rotationPitch) {
      return rotationRadius <= 0.01 && !rotationPitch && rotationAngle >= Math.PI;
    }

    getProjectedVectorLengthSquared(initialVec, targetVec, targetLen) {
      let projectedVectorLengthSquared = initialVec.clone().projectOnVector(targetVec).multiplyScalar(targetLen).lengthSq();
      return projectedVectorLengthSquared;
    }

    generateEllipsoidData(ellipseData, rotationAxis) {
      let dir1 = this.utils.initVector3(ellipseData.directionVector1),
          dir2 = this.utils.initVector3(ellipseData.directionVector2);
      let dir3 = new this.THREE.Vector3().crossVectors(dir1, dir2).normalize();
      let projOneLenSq = this.getProjectedVectorLengthSquared(rotationAxis, dir1, ellipseData.radius1);
      let projTwoLenSq = this.getProjectedVectorLengthSquared(rotationAxis, dir2, ellipseData.radius2);
      let radius3 = Math.sqrt(projOneLenSq + projTwoLenSq);
      let ellipsoidData = { ...ellipseData,
        radius3,
        directionVector3: dir3
      };
      return ellipsoidData;
    }

  }

  _exports.Ellipse = Ellipse;
});